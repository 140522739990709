import React from 'react';

import FlexListTable from 'components/FlexListTable/FlexListTable';
import { USER_UPDATE_FORM } from 'routes/constants';
import { useGetUsersQuery } from 'services/rtkApi';
import { createCapacityURLForThisMonth } from 'utils';

const UserTable = () => {
  const { data, error, isLoading } = useGetUsersQuery();

  console.log(data);

  // Example usage with link buttons

  // Optional: Validate or parse data with Zod (if needed)
  //   const validatedData = data ? projectSchemaList.parse(data.results[0]) : [];

  const columns = React.useMemo(
    () => [
      { Header: 'ID', accessor: 'id' },
      { Header: 'Name', accessor: 'name' },
      { Header: 'Created', accessor: 'created_at' },
    ],
    []
  );

  // Set user month to current month
  // const capacityLink = createCapacityURLForThisMonth(USER_MONTH_CAPACITY_GET);

  // const linkButtons = [
  //   {
  //     label: 'Plan',
  //     link: capacityLink,
  //   },
  //   {
  //     label: 'Edit',
  //     link: USER_UPDATE_FORM,
  //   },
  // ];

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error loading users</div>;

  return (
    <FlexListTable
      columns={columns}
      data={data.results || []}
      linkPattern={USER_UPDATE_FORM}
      // linkButtons={linkButtons}
    />
  );
};

export default UserTable;
