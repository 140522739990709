import React from 'react';
import { Link } from 'react-router-dom';

import { PRICING, GOOGLE_API_PERMISSIONS_REGISTER } from 'routes/constants';

const SettingsSubMenu = () => {
  const secondaryNavigation = [
    { name: 'Permissions', href: GOOGLE_API_PERMISSIONS_REGISTER, current: true },
    { name: 'Plan', href: PRICING, current: true },
  ];

  return (
    <nav className="flex overflow-x-auto border-b border-white/10">
      <ul
        role="list"
        className="flex min-w-full flex-none gap-x-6 text-sm font-semibold leading-6 text-gray-400"
      >
        {secondaryNavigation.map((item) => (
          <li key={item.name}>
            <Link to={item.href} className={item.current ? 'text-indigo-400' : ''}>
              {item.name}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default SettingsSubMenu;
