import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Button from 'components/Button/Button';
import SettingsSubMenu from 'components/SettingsSubMenu/SettingsSubMenu';
import { GOOGLE_API_OAUTH2_CALLBACK } from 'routes/constants';
import { getGoogleAPIAuthGetRedirectUrl } from 'services/auth/auth';
import { setGoogleAPIAuthStatus } from 'services/auth/authSlice';
import { clearFormError } from 'services/errors/errorSlice';
import PageLayout from 'templates/PageLayout';
import PageLayoutInner from 'templates/PageLayoutInner';
import PageLayoutInnerStrip from 'templates/PageLayoutInnerStrip';

const GoogleAPIAuthRegister = () => {
  const dispatch = useDispatch();
  const [isAuthorized, setIsAuthorized] = useState(false);

  // Get any errors that were sent to redux
  const formErrors = useSelector((state) => state.error.formErrors);
  const googleAPIAuthRegisterErrors = formErrors?.googleAPIAuthRegister || [];

  const handleGoogleLogin = async () => {
    dispatch(clearFormError('googleAPIAuthRegister'));
    try {
      const redirectUrl = await getGoogleAPIAuthGetRedirectUrl();
      console.log(redirectUrl);
      window.location.href = redirectUrl;
    } catch (error) {
      console.error('Failed to get Google API auth redirect URL:', error);
    }
  };

  // Check to see if we're authorized and provide feedback to user.
  useEffect(() => {
    const checkAuthStatus = async () => {
      try {
        const status = await setGoogleAPIAuthStatus();
        console.log(status);
        setIsAuthorized(status.payload);
      } catch (error) {
        console.error('Failed to get Google API auth status:', error);
      }
    };

    checkAuthStatus();
  }, []);

  return (
    <PageLayout isItGrey>
      <div>
        <PageLayoutInnerStrip>
          <SettingsSubMenu />
        </PageLayoutInnerStrip>
        <PageLayoutInnerStrip>
          <h1 className="text-xl font-semibold leading-7 text-gray-900">Permission</h1>
        </PageLayoutInnerStrip>
        <PageLayoutInnerStrip>
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <div className="bg-white shadow sm:rounded-lg">
              <div className="px-4 py-5 sm:p-6">
                <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                  <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                    Google Access
                  </h2>
                  <p>We need some permissions to auto fill our forms and work with your data.</p>
                  <p>Specifically we&apos;re asking for permissions to GA4 and BigQuery.</p>
                  <p>
                    If you&apos;d prefer to manage this through a service account please get in
                    touch.
                  </p>
                </div>
                {googleAPIAuthRegisterErrors.length > 0 && (
                  <div className="bg-red-100 border border-red-400 text-red-700 rounded p-4">
                    {googleAPIAuthRegisterErrors.map((error, index) => (
                      <p key={index}>{error}</p>
                    ))}
                  </div>
                )}
                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm space-y-6">
                  <Button
                    onClick={handleGoogleLogin}
                    variant="primary"
                    className="w-full"
                    disabled={isAuthorized}
                    statusColour={isAuthorized ? 'success' : ''}
                  >
                    {isAuthorized ? 'Permission granted' : 'Share Access'}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </PageLayoutInnerStrip>
        <PageLayoutInner />
      </div>
    </PageLayout>
  );
};

export default GoogleAPIAuthRegister;
