import React from 'react';

const LoginActivate = () => {
  return (
    <div className="sm:mx-auto sm:w-full sm:max-w-md">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Check your email for an activation link
        </h2>
      </div>
      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm space-y-6">
        <div>Check your email for an activation email.</div>
      </div>
    </div>
  );
};

export default LoginActivate;
