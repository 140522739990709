import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import { activateAccount } from 'services/auth/auth';

const LoginActivated = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [activationStatus, setActivationStatus] = useState(null);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const uid = params.get('uid');
    const token = params.get('token');

    if (uid && token) {
      activateAccount(uid, token).then(() => {
        // navigate('/login');
        return null; // Added return statement
      });
      // .catch(() => {
      //   setActivationStatus('Activation has been unsuccessful.');
      //   throw new Error('Activation failed'); // Added throw statement
      // });
    } else {
      setActivationStatus('Invalid activation link.');
    }
  }, [location.search, navigate]);

  return (
    <div className="sm:mx-auto sm:w-full sm:max-w-md">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Activating your account
        </h2>
      </div>
      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm space-y-6">
        {activationStatus ? (
          <div className="text-center text-red-600">{activationStatus}</div>
        ) : (
          <LoadingSpinner />
        )}
      </div>
    </div>
  );
};

export default LoginActivated;
