// In some page component
import React from 'react';

import SettingsSubMenu from 'components/SettingsSubMenu/SettingsSubMenu';
import { ORGGROUP_CATEGORY_CREATE_FORM } from 'routes/constants';
import PageLayout from 'templates/PageLayout';
import PageLayoutInner from 'templates/PageLayoutInner';
import PageLayoutInnerStrip from 'templates/PageLayoutInnerStrip';

const AccountSettings = () => {
  return (
    <PageLayout isItGrey>
      <div>
        <PageLayoutInnerStrip>
          <SettingsSubMenu />
        </PageLayoutInnerStrip>
        <PageLayoutInnerStrip>
          <h1 className="text-xl font-semibold leading-7 text-gray-900">Settings</h1>
        </PageLayoutInnerStrip>
        <PageLayoutInner />
      </div>
    </PageLayout>
  );
};

export default AccountSettings;
