import PropTypes from 'prop-types';
import React from 'react';

function PageLayoutInnerStrip({ children }) {
  return (
    <div
      className={`px-4 sm:px-6 lg:px-8 border-b border-gray-900/10 pt-5 py-4 flex justify-between items-center`}
    >
      {children}
    </div>
  );
}

export default PageLayoutInnerStrip;

PageLayoutInnerStrip.propTypes = {
  children: PropTypes.node,
};
