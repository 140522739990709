import PropTypes from 'prop-types';
import React, { useState, Fragment } from 'react';

function LoggedOutLayout({ children }) {
  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      {/* Content area */}
      {children}
    </div>
  );
}

export default LoggedOutLayout;

LoggedOutLayout.propTypes = {
  children: PropTypes.node,
};
