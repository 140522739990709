import { put, takeEvery } from 'redux-saga/effects';

import { addGlobalError } from './errorSlice';

// This saga only handles non-permission API errors
function* handleNonAuthApiError(action) {
  const { status, message } = action.payload || {};
  // Log action for debugging
  // Check if the error is not a permission error and falls outside the successful status code range
  console.log(status);
  console.log(message);
  if (status !== 401 && status !== 403 && (status < 200 || status > 299)) {
    // Add the error to global errors
    yield put(addGlobalError(message || 'An error occurred during the request.'));
  } else if ((status === 401 || status === 403) && 'Sorry' in message) {
    yield put(addGlobalError(message || 'You don&apos;t have permission to do that.'));
  }
}

function* watchNonAuthApiErrors() {
  yield takeEvery(
    (action) => action.type.endsWith('rejected') && ![401, 403].includes(action.payload?.status),
    handleNonAuthApiError
  );
}

export { watchNonAuthApiErrors };
