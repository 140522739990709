import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Button from 'components/Button/Button';
import { clearFormError } from 'services/errors/errorSlice';
import { useUpdateCurrentUserMutation } from 'services/rtkApi';
import LoggedOutLayout from 'templates/LoggedOutLayout';

const SocialRegister = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { email, name } = useSelector((state) => state.user);
  const [updateCurrentUser, { isLoading, isSuccess, isError, error }] =
    useUpdateCurrentUserMutation();

  useEffect(() => {
    if (isSuccess) {
      navigate('/'); // Redirect to homepage after successful activation
    }
  }, [isSuccess, navigate]);

  const handleActivation = async () => {
    dispatch(clearFormError('register'));

    try {
      await updateCurrentUser({ is_validated: true }).unwrap();
    } catch (err) {
      // Handle activation errors here if needed
      console.error(err);
    }
  };

  return (
    <div className="sm:mx-auto sm:w-full sm:max-w-md">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Activate your account
        </h2>
      </div>
      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm space-y-6">
        <div>
          <label>Email: {email}</label>
        </div>
        <div>
          <label>Name: {name}</label>
        </div>
        <Button
          onClick={handleActivation}
          variant="primary"
          className="w-full"
          disabled={isLoading}
        >
          {isLoading ? 'Activating...' : 'Activate your account'}
        </Button>
        {isError && <div className="text-red-500">Failed to activate account: {error.message}</div>}
      </div>
    </div>
  );
};

export default SocialRegister;
