import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

import { HOME, ACCOUNT_SETTINGS, PIPELINE_CREATE_FORM, PIPELINE_LIST } from 'routes/constants';

import pipelineLogo from '../../../assets/images/pipeline-logo-white.png';

import SidebarNav from './SidebarNav';

const navigation = [
  { section: 'Pipelines' },
  // {
  //   name: 'Management',
  //   current: false,
  //   children: [
  //     { name: 'Accounts', href: ACCOUNT_LIST },
  //     { name: 'Users', href: USER_LIST },
  //   ],
  // },
  {
    name: 'Pipelines',
    current: false,
    children: [
      { name: 'Create Pipeline', href: PIPELINE_CREATE_FORM },
      { name: 'List Pipelines', href: PIPELINE_LIST },
    ],
  },
  {
    name: 'Settings',
    current: false,
    href: ACCOUNT_SETTINGS,
  },
];

// Function to group data by category
const groupByCategory = (data) => {
  return data.reduce((acc, item) => {
    const category = item.category_name || 'Default'; // Use 'Default' for items without a category
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push(item);
    return acc;
  }, {});
};

export default function Sidebar({ setSidebarOpen, sidebarOpen }) {
  // if (data) {
  //   // Transform the data
  //   const groupedData = groupByCategory(data);

  //   // Create navigation entries based on grouped data
  //   Object.keys(groupedData).forEach((category) => {
  //     const navEntry = {
  //       name: category,
  //       current: false,
  //       children: [],
  //     };

  //     const projectsChildren = [];
  //     const usersChildren = [];

  //     groupedData[category].forEach((item) => {
  //       const userGroupLink = createCapacityURL(ORGGROUP_USER_CAPACITY_GET).replace(':id', item.id);
  //       const projectGroupLink = createCapacityURL(ORGGROUP_PROJECT_CAPACITY_GET).replace(
  //         ':id',
  //         item.id
  //       );

  //       // Create separate arrays for projects and users
  //       projectsChildren.push({ name: `Projects - ${item.name}`, href: projectGroupLink });
  //       usersChildren.push({ name: `Users - ${item.name}`, href: userGroupLink });
  //     });

  //     // Add projects and users to children, ensuring projects are listed first
  //     navEntry.children = [...projectsChildren, ...usersChildren];

  //     navigation.unshift(navEntry);
  //   });
  //   navigation.unshift({ section: 'Plan' });
  // }

  return (
    <div>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900/80" />
          </Transition.Child>

          <div className="fixed inset-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button
                      type="button"
                      className="-m-2.5 p-2.5"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-teal-900 px-6 pb-4">
                  <div className="flex h-16 shrink-0 items-center">
                    <img className="h-16 w-auto" src={pipelineLogo} alt="Pipeline" />
                  </div>
                  <SidebarNav navigation={navigation} />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-teal-900 px-6 pb-4 mt-">
          <div className="flex h-16 shrink-0 items-center mt-4">
            <Link to={HOME}>
              <img className="h-16 w-auto" src={pipelineLogo} alt="Piped Out Pipeline" />
            </Link>
          </div>
          <SidebarNav navigation={navigation} />
        </div>
      </div>
    </div>
  );
}
