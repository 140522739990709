import PropTypes from 'prop-types';
import React from 'react';

const SignedOutContainer = ({ children }) => {
  return (
    <div className="sm:mx-auto sm:w-full sm:max-w-md rounded-lg bg-gray-200 shadow">
      <div className="rounded-tl-lg rounded-tr-lg sm:rounded-tr-none group relative bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500">
        {children}
      </div>
    </div>
  );
};

SignedOutContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

// Add in prop validation

export default SignedOutContainer;
