import React, { useState, useEffect } from 'react';

// Tabs.js
function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function Tabs({ tabs, errorCounts, currentTab, onTabClick }) {
  return (
    <div className="pb-6">
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 focus:border-blue-500 focus:ring-blue-500"
          value={currentTab}
          onChange={(e) => onTabClick(e.target.value)}
        >
          {tabs.map((tab) => (
            <option key={tab.key} value={tab.key}>
              {tab.tabName}
            </option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <nav className="flex space-x-4" aria-label="Tabs">
          {tabs.map((tab) => (
            <a
              key={tab.key}
              href="#"
              onClick={(e) => {
                e.preventDefault();
                onTabClick(tab.key);
              }}
              className={classNames(
                tab.key === currentTab
                  ? 'bg-blue-100 text-blue-700'
                  : 'text-gray-500 hover:text-gray-700',
                'rounded-md px-3 py-2 text-sm font-medium'
              )}
              aria-current={tab.key === currentTab ? 'page' : undefined}
            >
              {tab.tabName}
              {errorCounts[tab.key] > 0 && (
                <span className="error-count inline-flex items-baseline rounded-full px-2.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0 bg-red-100 text-red-800">
                  ({errorCounts[tab.key]})
                </span>
              )}
            </a>
          ))}
        </nav>
      </div>
    </div>
  );
}

export default Tabs;

// function classNames(...classes) {
//   return classes.filter(Boolean).join(' ');
// }

// function Tabs({ tabs, currentTab, onTabClick }) {
//   return (
//     <div>
//       <div className="sm:hidden">
//         <label htmlFor="tabs" className="sr-only">
//           Select a tab
//         </label>
//         <select
//           id="tabs"
//           name="tabs"
//           className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
//           value={currentTab}
//           onChange={(e) => onTabClick(e.target.value)}
//         >
//           {tabs.map((tab) => (
//             <option key={tab.key} value={tab.key}>
//               {tab.tabName}
//             </option>
//           ))}
//         </select>
//       </div>
//       <div className="hidden sm:block">
//         <nav className="isolate flex divide-x divide-gray-200 rounded-lg shadow" aria-label="Tabs">
//           {tabs.map((tab, index) => (
//             <a
//               key={tab.key}
//               href="#"
//               onClick={(e) => {
//                 e.preventDefault();
//                 onTabClick(tab.key);
//               }}
//               className={classNames(
//                 tab.key === currentTab
//                   ? 'text-gray-900 bg-white'
//                   : 'text-gray-500 hover:text-gray-700 hover:bg-gray-50',
//                 index === 0 ? 'rounded-l-lg' : '',
//                 index === tabs.length - 1 ? 'rounded-r-lg' : '',
//                 'group relative min-w-0 flex-1 overflow-hidden py-4 px-4 text-center text-sm font-medium focus:z-10'
//               )}
//               aria-current={tab.key === currentTab ? 'page' : undefined}
//             >
//               {tab.tabName}
//               <span
//                 aria-hidden="true"
//                 className={classNames(
//                   tab.key === currentTab ? 'bg-indigo-500' : 'bg-transparent',
//                   'absolute inset-x-0 bottom-0 h-0.5'
//                 )}
//               />
//             </a>
//           ))}
//         </nav>
//       </div>
//     </div>
//   );
// }

// export default Tabs;
