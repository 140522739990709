// src/store.js
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
import { createReduxHistoryContext } from 'redux-first-history';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';

import authReducer from 'services/auth/authSlice';
import errorReducer from 'services/errors/errorSlice';
import api from 'services/rtkApi';
import rootSaga from 'services/saga';
import userReducer from 'services/user/userSlice';

const sagaMiddleware = createSagaMiddleware();

// Create the history config
const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: createBrowserHistory(),
});

// Define the persist configuration
const persistConfig = {
  key: 'root',
  storage,
  blacklist: [api.reducerPath, 'error', 'router'], // blacklist RTKquery.
};

// Combine reducers using combineReducers
const rootReducer = combineReducers({
  router: routerReducer,
  [api.reducerPath]: api.reducer,
  error: errorReducer,
  auth: authReducer,
  user: userReducer,
});

// Create a persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(api.middleware, routerMiddleware, sagaMiddleware),
});

// Create history
export const history = createReduxHistory(store);

sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);

export default store;
