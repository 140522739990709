// In some page component
import React from 'react';

import MarkdownRenderer from 'components/MarkdownRenderer/MarkdownRenderer';
import markdownContent from 'content/terms.md';
import LoggedOutLayout from 'templates/LoggedOutLayout';
import PageLayoutInner from 'templates/PageLayoutInner';
import PageLayoutInnerStrip from 'templates/PageLayoutInnerStrip';

const TermsAndConditions = () => {
  return (
    <LoggedOutLayout isItGrey>
      <div>
        <PageLayoutInnerStrip>
          <h1 className="text-xl font-semibold leading-7 text-gray-900">Terms and Conditions</h1>
        </PageLayoutInnerStrip>
        <PageLayoutInner>
          <div className="prose">
            <MarkdownRenderer markdown={markdownContent} />
          </div>
        </PageLayoutInner>
      </div>
    </LoggedOutLayout>
  );
};

export default TermsAndConditions;
