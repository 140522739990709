import React from 'react';
import { useParams } from 'react-router-dom';

import CrudForm from 'components/Forms/CrudForm/CrudForm';
import { sourceGA4BigQuerySchemaForm } from 'schemas';
import {
  useUpdateSourceGA4BigQueryMutation,
  useGetSourceGA4BigQueryByIdQuery,
  useAddSourceGA4BigQueryMutation,
} from 'services/rtkApi'; // adjust the import based on your API service setup

const SourceGA4BigQueryForm = ({ onSuccess }) => {
  const { id } = useParams(); // This will get the entityId from the URL
  const isUpdate = Boolean(id);
  const formType = isUpdate ? 'update' : 'create';

  const mutationHook = isUpdate
    ? useUpdateSourceGA4BigQueryMutation
    : useAddSourceGA4BigQueryMutation;
  const queryHook = isUpdate ? useGetSourceGA4BigQueryByIdQuery : undefined;

  return (
    <CrudForm
      entityId={id}
      key={`pipeline_${formType}`}
      formType={formType}
      mutationHook={mutationHook}
      queryHook={queryHook}
      schema={sourceGA4BigQuerySchemaForm}
      tabs={[
        {
          key: 'Main',
          tabName: 'Main',
          fieldList: ['name'],
          description: 'Setup your GA4 export',
        },
      ]}
      onSuccess={onSuccess}
    />
  );
};

export default SourceGA4BigQueryForm;
