import { createSlice } from '@reduxjs/toolkit';

import { setAuthToken } from 'services/auth/authApi';

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isLoading: false,
    error: null,
    accessToken: null,
    refreshToken: null,
    loginSuccess: false,
    accountOverride: null,
    googleAPIAuthStatus: false,
  },
  reducers: {
    setTokens: (state, action) => {
      state.accessToken = action.payload.access;
      state.refreshToken = action.payload.refresh;
      setAuthToken(action.payload.access, 'JWT');
    },
    setAccessToken: (state, action) => {
      state.accessToken = action.payload;
      setAuthToken(action.payload, 'JWT');
    },
    loginRequest: (state) => {
      state.isLoading = true;
      state.loginSuccess = false;
    },
    loginSuccess: (state) => {
      state.isLoading = false;
      state.loginSuccess = true;
    },
    loginFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.loginSuccess = false;
    },
    registerRequest: (state) => {
      state.isLoading = true;
      state.registerSuccess = false; // Reset on new register request
    },
    registerSuccess: (state) => {
      state.isLoading = false;
      state.registerSuccess = true; // Set to true on successful registration
    },
    registerFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.registerSuccess = false; // Set to false on registration failure
    },
    getGoogleAPIAuthRedirectUrlRequest: (state) => {
      state.isLoading = true;
    },
    getSocialRedirectUrlRequest: (state) => {
      state.isLoading = true;
    },
    socialLoginRequest: (state) => {
      state.isLoading = true;
      state.loginSuccess = false; // Reset on new login request
    },
    socialLoginSuccess: (state) => {
      state.isLoading = false;
      state.loginSuccess = true; // Set to true on successful login
    },
    socialLoginFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.loginSuccess = false; // Set to false on login failure
    },
    logout: (state) => {
      state.accessToken = null;
      state.refreshToken = null;
      state.isLoading = false;
      state.error = null;
      state.loginSuccess = false;
      setAuthToken(null);
    },
    triggerLogout: (state) => {
      state.isLoading = true; // Optional: Set loading state
    },
    resetLoginSuccess: (state) => {
      state.loginSuccess = false;
    },
    resetRegisterSuccess: (state) => {
      state.registerSuccess = false;
    },
    setAccountOverride: (state, action) => {
      state.accountOverride = action.payload;
    },
    googleAPIAuthRequest: (state) => {
      state.isLoading = true;
    },
    setGoogleAPIAuthStatus: (state, action) => {
      state.googleAPIAuthStatus = action;
    },
  },
});

export const {
  loginRequest,
  loginSuccess,
  loginFailure,
  socialLoginRequest,
  socialLoginSuccess,
  socialLoginFailure,
  registerRequest,
  registerSuccess,
  registerFailure,
  setTokens,
  setAccessToken,
  getSocialRedirectUrlRequest,
  logout,
  triggerLogout,
  resetLoginSuccess,
  resetRegisterSuccess,
  setAccountOverride,
  setGoogleAPIAuthStatus,
  googleAPIAuthRequest,
  getGoogleAPIAuthRedirectUrlRequest,
} = authSlice.actions;
export default authSlice.reducer;
