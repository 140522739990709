import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import * as route from 'routes/constants';

function authHoc(ComposedComponent, userMustBeLoggedIn) {
  const Authenticate = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const accessToken = useSelector((state) => state.auth.accessToken);

    useEffect(() => {
      const isLoggedIn = !!accessToken;
      if (userMustBeLoggedIn !== isLoggedIn) {
        if (userMustBeLoggedIn && !isLoggedIn) {
          navigate(route.LOGIN, { state: { from: location } });
        } else if (!userMustBeLoggedIn && isLoggedIn) {
          navigate(route.HOME);
        }
      }
    }, [accessToken, userMustBeLoggedIn, navigate, location]);

    return userMustBeLoggedIn === !!accessToken ? <ComposedComponent {...props} /> : null;
  };

  return Authenticate;
}

function authHocLoggedOut(ComposedComponent) {
  const Authenticate = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const accessToken = useSelector((state) => state.auth.accessToken);

    useEffect(() => {
      const isLoggedIn = !!accessToken;
      if (isLoggedIn) {
        navigate(route.HOME, { state: { from: location } });
      }
    }, [accessToken, navigate, location]);

    const isLoggedIn = !!accessToken; // Define isLoggedIn here

    return !isLoggedIn ? <ComposedComponent {...props} /> : null;
  };

  return Authenticate;
}

export default authHoc;
export { authHocLoggedOut };
