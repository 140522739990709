// Auth
export const LOGIN = '/login';
export const LOGOUT = '/logout';
export const REGISTER = '/register';
export const FORGOT_PASSWORD = '/forgot-password';
export const SOCIAL_LOGIN_REDIRECT = '/social/callback';
export const SOCIAL_REGISTER = '/social-register';
export const LOGIN_ACTIVATE = '/login/activate';
export const LOGIN_ACTIVATED = '/login/activated';

// Google API OAuth2

export const GOOGLE_API_PERMISSIONS_CALLBACK = '/permissions/google-api/callback';
export const GOOGLE_API_PERMISSIONS_REGISTER = '/permissions/google-api/register';

// Accounts
export const ACCOUNT_CREATE_FORM = '/accounts/new';
export const ACCOUNT_UPDATE_FORM = '/accounts/edit/:id';
export const ACCOUNT_LIST = '/accounts';

// Pipeline
export const PIPELINE_CREATE_FORM = '/pipelines/new';
export const PIPELINE_UPDATE_FORM = '/pipelines/edit/:id';
export const PIPELINE_LIST = '/pipelines';
export const PIPELINE_ALL_SOURCE_LIST = '/pipelines/:id/';

// GA4 BigQuery Export
export const SOURCE_GA4_BIGQUERY_CREATE_FORM = '/source/ga4-bigquery/new';
export const SOURCE_GA4_BIGQUERY_UPDATE_FORM = '/source/ga4-bigquery/edit/:id';
export const SOURCE_GA4_BIGQUERY_LIST = '/source/ga4-bigquery';

// Users
export const USER_CREATE_FORM = '/users/new';
export const USER_UPDATE_FORM = '/users/edit/:id';
export const USER_LIST = '/users';

// User months

// Legal
export const TERMS_AND_CONDITIONS = '/terms-and-conditions';
export const PRIVACY_POLICY = '/privacy-policy';

// Pricing
export const PRICING = '/pricing';

// Settings
export const SETTINGS = '/settings';
export const ACCOUNT_SETTINGS = '/account/settings';

// Homepage
export const HOME = '/';

// API URLS
export const GOOGLE_API_OAUTH2_CALLBACK = '/google-api-oauth2/callback';
