import { z } from 'zod';

export const accountSchemaForm = z.object({
  id: z.number().optional(),
  name: z.string(),
  contact_info: z.string(),
  users: z.number(),
});

export const accountSchemaList = z.object({
  id: z.number().optional(),
  name: z.string(),
  contact_info: z.string(),
});
