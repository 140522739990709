import * as React from 'react';

const PricingPage = () => {
  // Paste the stripe-pricing-table snippet in your React component
  return (
    <stripe-pricing-table
      pricing-table-id="prctbl_1PdZNUJT4nbABWkoMRuZlW37"
      publishable-key="pk_test_51PdJjwJT4nbABWkok2BAlrJai1NAUIujnkzFIgSfeQ9WTCoFF49B71XZitapNkazuJy19PFtaI8Owa9Gv3Lejqbl00l4dfKGyW"
    />
  );
};

export default PricingPage;
