import React from 'react';
import { Routes, Route } from 'react-router-dom';

import ForgotPassword from 'pages/auth/ForgotPassword';
import Login from 'pages/auth/Login';
import LoginActivate from 'pages/auth/LoginActivate';
import LoginActivated from 'pages/auth/LoginActivated';
import Register from 'pages/auth/Register';
import SocialAuthRedirect from 'pages/auth/SocialAuthRedirect';
import SocialRegister from 'pages/auth/SocialRegister';
import Home from 'pages/Home';
import PrivacyPolicyPage from 'pages/legal/PrivacyPolicy';
import TermsAndConditionsPage from 'pages/legal/TermsAndConditions';
import AccountFormPage from 'pages/models/accounts/AccountFormPage';
import AccountListPage from 'pages/models/accounts/AccountListPage';
import AccountSettings from 'pages/models/AccountSettingsPage';
import PipelineAllSourceListPage from 'pages/models/pipelines/PipelineAllSourceListPage';
import PipelineFormPage from 'pages/models/pipelines/PipelineFormPage';
import PipelineListPage from 'pages/models/pipelines/PipelineListPage';
import SourceGA4BigQueryFormPage from 'pages/models/source_ga4_bigquery/SourceGA4BigQueryFormPage';
import SourceGA4BigQueryListPage from 'pages/models/source_ga4_bigquery/SourceGA4BigQueryListPage';
import UserFormPage from 'pages/models/users/UserFormPage';
import UserListPage from 'pages/models/users/UserListPage';
import GoogleAPIAuthRedirect from 'pages/settings/GoogleAPIAuthRedirect';
import GoogleAPIAuthRegister from 'pages/settings/GoogleAPIAuthRegister';
import PricingPage from 'pages/settings/subscription/StripePricingPage';
import * as route from 'routes/constants';
import authHoc, { authHocLoggedOut } from 'services/auth/authHoc';

// Wrap components with authHoc
const AuthenticatedHomePage = authHoc(Home, true);
const AuthenticatedAccountFormPage = authHoc(AccountFormPage, true);
const AuthenticatedAccountListPage = authHoc(AccountListPage, true);

// User
const AuthenticatedUserListPage = authHoc(UserListPage, true);
const AuthenticatedUserFormPage = authHoc(UserFormPage, true);
const AuthenticatedAccountSettings = authHoc(AccountSettings, true);

// Pipelines
const AuthenticatedPipelineAllSourceListPage = authHoc(PipelineAllSourceListPage, true);
const AuthenticatedPipelineFormPage = authHoc(PipelineFormPage, true);
const AuthenticatedPipelineListPage = authHoc(PipelineListPage, true);

// GA4 BigQuery Export
const AuthenticatedSourceGA4BigQueryFormPage = authHoc(SourceGA4BigQueryFormPage, true);
const AuthenticatedSourceGA4BigQueryListPage = authHoc(SourceGA4BigQueryListPage, true);

// Permissions
const AuthenticatedGoogleAPIAuthRedirect = authHoc(GoogleAPIAuthRedirect, true);
const AuthenticatedGoogleAPIAuthRegister = authHoc(GoogleAPIAuthRegister, true);

// Wrap signin with unauth redirects
const UnauthenticatedLogin = authHocLoggedOut(Login);
const UnauthenticatedRegister = authHocLoggedOut(Register);
const UnauthenticatedSocialAuthRedirect = authHocLoggedOut(SocialAuthRedirect);
const UnauthenticatedSocialRegister = authHocLoggedOut(SocialRegister);
const UnauthenticatedLoginActivate = authHocLoggedOut(LoginActivate);
const UnauthenticatedLoginActivated = authHocLoggedOut(LoginActivated);
const UnauthenticatedForgotPassword = authHocLoggedOut(ForgotPassword);

// const TermsAndConditionsPage = authHoc(TermsAndConditionsPage, true);
// const PrivacyPolicyPage = authHoc(PrivacyPolicyPage, true);

const AuthenticatedPricingPage = authHoc(PricingPage, true);

const AppRoutes = () => (
  <Routes>
    <Route path="/" element={<AuthenticatedHomePage />} />

    {/* User Auth */}
    <Route path={route.LOGIN} element={<UnauthenticatedLogin />} />
    <Route path={route.LOGIN_ACTIVATE} element={<UnauthenticatedLoginActivate />} />
    <Route path={route.LOGIN_ACTIVATED} element={<UnauthenticatedLoginActivated />} />
    <Route path={route.REGISTER} element={<UnauthenticatedRegister />} />
    <Route path={route.SOCIAL_LOGIN_REDIRECT} element={<UnauthenticatedSocialAuthRedirect />} />
    <Route path={route.SOCIAL_REGISTER} element={<UnauthenticatedSocialRegister />} />
    <Route path={route.FORGOT_PASSWORD} element={<UnauthenticatedForgotPassword />} />

    {/* Legal */}
    <Route path={route.TERMS_AND_CONDITIONS} element={<TermsAndConditionsPage />} />
    <Route path={route.PRIVACY_POLICY} element={<PrivacyPolicyPage />} />

    {/* Accounts */}
    <Route path={route.ACCOUNT_CREATE_FORM} element={<AuthenticatedAccountFormPage />} />
    <Route path={route.ACCOUNT_UPDATE_FORM} element={<AuthenticatedAccountFormPage />} />
    <Route path={route.ACCOUNT_LIST} element={<AuthenticatedAccountListPage />} />

    {/* Pipelines */}
    <Route path={route.PIPELINE_CREATE_FORM} element={<AuthenticatedPipelineFormPage />} />
    <Route path={route.PIPELINE_UPDATE_FORM} element={<AuthenticatedPipelineFormPage />} />
    <Route path={route.PIPELINE_LIST} element={<AuthenticatedPipelineListPage />} />
    <Route
      path={route.PIPELINE_ALL_SOURCE_LIST}
      element={<AuthenticatedPipelineAllSourceListPage />}
    />

    {/* GA4 BigQuery Export */}
    <Route
      path={route.SOURCE_GA4_BIGQUERY_CREATE_FORM}
      element={<AuthenticatedSourceGA4BigQueryFormPage />}
    />
    <Route
      path={route.SOURCE_GA4_BIGQUERY_UPDATE_FORM}
      element={<AuthenticatedSourceGA4BigQueryFormPage />}
    />
    <Route
      path={route.SOURCE_GA4_BIGQUERY_LIST}
      element={<AuthenticatedSourceGA4BigQueryListPage />}
    />

    {/* Users */}
    <Route path={route.USER_CREATE_FORM} element={<AuthenticatedUserFormPage />} />
    <Route path={route.USER_UPDATE_FORM} element={<AuthenticatedUserFormPage />} />
    <Route path={route.USER_LIST} element={<AuthenticatedUserListPage />} />

    {/* Pricing */}
    <Route path={route.PRICING} element={<AuthenticatedPricingPage />} />

    {/* Settings */}

    <Route
      path={route.GOOGLE_API_PERMISSIONS_CALLBACK}
      element={<AuthenticatedGoogleAPIAuthRedirect />}
    />
    <Route
      path={route.GOOGLE_API_PERMISSIONS_REGISTER}
      element={<AuthenticatedGoogleAPIAuthRegister />}
    />

    <Route path={route.ACCOUNT_SETTINGS} element={<AuthenticatedAccountSettings />} />
    {/* Add other routes as needed */}
  </Routes>
);

export default AppRoutes;
