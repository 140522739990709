import PropTypes from 'prop-types';
import React, { useState } from 'react';

import Sidebar from 'components/Sidebar/Sidebar'; // Adjust with your Sidebar import
import TopBar from 'components/TopBar/TopBar'; // Adjust with your TopBar import

function LoggedInLayout({ children }) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  return (
    <div className="">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      {/* Content area */}
      <div className="lg:pl-72">
        {/* Top Bar */}
        <TopBar setSidebarOpen={setSidebarOpen} />

        {/* Main content */}
        {children}
      </div>
    </div>
  );
}

export default LoggedInLayout;

LoggedInLayout.propTypes = {
  children: PropTypes.node,
};
