import PropTypes from 'prop-types';
import React from 'react';

function PageLayoutInner({ children }) {
  return <div className={`px-4 sm:px-6 lg:px-8 mt-5 `}>{children}</div>;
}

export default PageLayoutInner;

PageLayoutInner.propTypes = {
  children: PropTypes.node,
};
