// In some page component
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import PipelineForm from 'components/_forms/pipelines/PipelineForm';
import { PIPELINE_ALL_SOURCE_LIST } from 'routes/constants';
import PageLayout from 'templates/PageLayout';
import PageLayoutInner from 'templates/PageLayoutInner';
import PageLayoutInnerStrip from 'templates/PageLayoutInnerStrip';
import { createURLWithParams } from 'utils';

const PipelineFormPage = () => {
  const navigate = useNavigate();
  const { id } = useParams(); // Get entityId from URL params
  const isUpdate = Boolean(id); // Determine if it's update mode
  const pageTitle = isUpdate ? 'Update Pipeline' : 'Create Pipeline'; // Set page title based on mode

  const handleSuccess = (entityId) => {
    const url = createURLWithParams(PIPELINE_ALL_SOURCE_LIST, { id: entityId });
    navigate(url);
  };

  return (
    <PageLayout isItGrey>
      <div>
        <PageLayoutInnerStrip>
          <h1 className="text-xl font-semibold leading-7 text-gray-900">{pageTitle}</h1>
        </PageLayoutInnerStrip>
        <PageLayoutInner>
          <PipelineForm entityId={id} onSuccess={handleSuccess} />
        </PageLayoutInner>
      </div>
    </PageLayout>
  );
};

export default PipelineFormPage;
