// Get the project data.

// In some page component
import React from 'react';
import { useParams } from 'react-router-dom';

import SourceGA4BigQueryTable from 'components/_tables/source_ga4_bigquery/SourceGA4BigQueryTable';
import PageLayout from 'templates/PageLayout';
import PageLayoutInner from 'templates/PageLayoutInner';
import PageLayoutInnerStrip from 'templates/PageLayoutInnerStrip';

const PipelineAllSourceList = () => {
  const { id } = useParams(); // Get entityId from URL params

  const handleSuccess = () => {
    // Handle what happens after successful form submission
    // Redirect or show a success message
  };

  return (
    <PageLayout isItGrey>
      <div>
        <PageLayoutInnerStrip>
          <h1 className="text-xl font-semibold leading-7 text-gray-900">Manage Pipeline</h1>
        </PageLayoutInnerStrip>
        <PageLayoutInner>
          <SourceGA4BigQueryTable pipelineId={id} />
        </PageLayoutInner>
      </div>
    </PageLayout>
  );
};

export default PipelineAllSourceList;
