import React from 'react';
import { useSelector } from 'react-redux';

import LoggedInLayout from './LoggedInLayout';
import LoggedOutLayout from './LoggedOutLayout';

function BaseLayout({ children }) {
  const accessToken = useSelector((state) => state.auth.accessToken);
  const is_active = useSelector((state) => state.user.is_active);
  const is_validated = useSelector((state) => state.user.is_validated);

  // If a user is not active and validated or
  // is active and not validated we treat them as logged out
  if ((!is_active && is_validated) || (is_active && !is_validated)) {
    return <LoggedOutLayout>{children}</LoggedOutLayout>;
  }

  if (accessToken) {
    return <LoggedInLayout>{children}</LoggedInLayout>;
  }
  return <LoggedOutLayout>{children}</LoggedOutLayout>;
}

export default BaseLayout;
