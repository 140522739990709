import { Disclosure } from '@headlessui/react';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import React, { useEffect, useState } from 'react';

import { classNames } from 'utils';

import SidebarLink from './SidebarLink'; // Adjust the import path as necessary
import SidebarProfile from './SidebarProfile';

function SidebarNav({ navigation }) {
  const [openMenus, setOpenMenus] = useState({});

  const handleToggle = (itemName) => {
    setOpenMenus((prevState) => ({
      ...prevState,
      [itemName]: !prevState[itemName],
    }));
  };

  return (
    <nav className="flex flex-1 flex-col">
      <ul role="list" className="flex flex-1 flex-col gap-y-3">
        {navigation.map((item, i) => (
          <li key={i}>
            {item.section ? (
              // Render a div with the section name if the `section` property exists
              <div className="text-xs font-semibold leading-6 text-gray-400">{item.section}</div>
            ) : !item.children ? (
              // Render a sidebar link if there are no children and no `section` property
              <SidebarLink href={item.href} current={item.current} name={item.name} />
            ) : (
              <Disclosure as="div" open={openMenus[item.name]}>
                {({ open }) => (
                  <>
                    <Disclosure.Button
                      onClick={() => handleToggle(item.name)}
                      className="hover:bg-teal-800 flex items-center w-full text-left rounded-md p-2 gap-x-3 text-sm leading-6 font-semibold text-white"
                    >
                      <ChevronRightIcon
                        className={classNames(
                          open ? 'rotate-90 text-white' : 'text-white',
                          'h-4 w-4 shrink-0'
                        )}
                        aria-hidden="true"
                      />
                      {item.name}
                    </Disclosure.Button>
                    <Disclosure.Panel as="ul" className="mt-1 px-2">
                      {item.children.map((subItem) => (
                        <li key={subItem.name}>
                          <SidebarLink
                            href={subItem.href}
                            current={subItem.current}
                            name={subItem.name}
                            indentedLevel={1}
                          />
                        </li>
                      ))}
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            )}
          </li>
        ))}
        <SidebarProfile />
      </ul>
    </nav>
  );
}

export default SidebarNav;
