import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { useTable, useSortBy } from 'react-table';

import Button from 'components/Button/Button';

function FlexListTable({
  columns,
  data,
  initialSort,
  linkPattern,
  linkButtons,
  hiddenColumnIds = ['id'],
  linkIdKey = 'id',
  linkIdString = ':id',
}) {
  const initialState = {
    hiddenColumns: hiddenColumnIds,
  };

  if (initialSort && initialSort.length) {
    initialState.sortBy = initialSort;
  }

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
      initialState,
    },
    useSortBy
  );

  function generateMinWidthStyle(column) {
    if (column.assignedWidth) {
      return {
        className: 'fixed-width-column',
        style: { minWidth: `${column.assignedWidth}rem`, maxWidth: `${column.assignedWidth}rem` },
      };
    }
    // For dynamic columns, you might only need the class name for equal distribution
    return { className: 'flex-column', style: {} };
  }

  const createLink = (row) => {
    return linkPattern.replace(linkIdString, row.original[linkIdKey]);
  };

  const renderRow = (row, rowIndex) => {
    prepareRow(row);
    const rowProps = row.getRowProps();

    if (!linkButtons) {
      const rowLink = createLink(row);
      return (
        <Link to={rowLink} key={rowIndex}>
          <div
            {...rowProps}
            className="flex flex-col md:flex-row divide-y divide-gray-200 mb-8 md:mb-0 bg-white border-b md:border-b-0 border-gray-200 hover:bg-gray-100"
          >
            {row.cells.map((cell, cellIndex) => {
              const { className, style } = generateMinWidthStyle(cell.column);
              return (
                <div
                  {...cell.getCellProps()}
                  key={cellIndex}
                  className={`px-6 py-4 text-sm text-gray-500 flex items-center ${className} ${
                    cellIndex === 0 ? 'border-t border-gray-200' : ''
                  }`}
                  style={style}
                >
                  {/* Label for mobile */}
                  <span className="md:hidden font-medium capitalize">
                    {cell.column.Header}:{' '}
                  </span>{' '}
                  {cell.render('Cell')}
                </div>
              );
            })}
          </div>
        </Link>
      );
    }
    return (
      <div
        {...rowProps}
        key={rowIndex}
        className="flex flex-col md:flex-row divide-y divide-gray-200 mb-8 md:mb-0 bg-white border-b md:border-b-0 border-gray-200"
      >
        {row.cells.map((cell, cellIndex) => {
          const { className, style } = generateMinWidthStyle(cell.column);
          return (
            <div
              {...cell.getCellProps()}
              key={cellIndex}
              className={`px-6 py-4 text-sm text-gray-500 flex items-center ${className} ${
                cellIndex === 0 ? 'border-t border-gray-200' : ''
              }`}
              style={style}
            >
              {/* Label for mobile */}
              <span className="md:hidden font-medium capitalize">{cell.column.Header}: </span>{' '}
              {cell.render('Cell')}
            </div>
          );
        })}
        <div className="flex space-x-2 p-4">
          {linkButtons.map((button, buttonIndex) => (
            <Button href={button.link.replace(':id', row.original.id)} key={buttonIndex}>
              {button.label}
            </Button>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="overflow-x-auto shadow-md sm:rounded-lg">
      <div {...getTableProps()} className="min-w-full divide-y divide-gray-200">
        <div className="bg-gray-50">
          {headerGroups.map((headerGroup, headerGroupIndex) => (
            <div
              {...headerGroup.getHeaderGroupProps()}
              key={headerGroupIndex}
              className="flex flex-col md:flex-row"
            >
              {headerGroup.headers.map((column, columnIndex) => {
                const { className, style } = generateMinWidthStyle(column);
                return (
                  <div
                    {...column.getHeaderProps(column.getSortByToggleProps())} // Make headers clickable for sorting
                    key={columnIndex}
                    className={`px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider ${className} ${
                      column.isSorted ? (column.isSortedDesc ? 'sorted-desc' : 'sorted-asc') : ''
                    }`} // Add classes to indicate sorting
                    style={style}
                  >
                    {column.render('Header')}
                  </div>
                );
              })}
            </div>
          ))}
        </div>
        <div {...getTableBodyProps()} className="">
          {rows.map((row, rowIndex) => {
            return renderRow(row, rowIndex);
          })}
        </div>
      </div>
    </div>
  );
}

FlexListTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  initialSort: PropTypes.array,
  linkPattern: PropTypes.string,
  linkButtons: PropTypes.array,
  hiddenColumnIds: PropTypes.array,
  linkIdKey: PropTypes.string,
  linkIdString: PropTypes.string,
};

export default FlexListTable;
