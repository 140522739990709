import PropTypes from 'prop-types';
import React from 'react';

function PageLayout({ children, isItGrey = true }) {
  const greyClass = isItGrey ? 'bg-gray-50' : '';
  return (
    <main className={`${greyClass} pb-5`}>
      <div className={``}>{children}</div>
    </main>
  );
}

export default PageLayout;

PageLayout.propTypes = {
  children: PropTypes.node,
  isItGrey: PropTypes.bool,
};
