// src/services/api/index.js
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import cookie from 'cookie';

import { handleDjangoDecimalFields } from 'utils/index';

const myBaseQuery = fetchBaseQuery({
  baseUrl: '/api/v1/',
  prepareHeaders: (headers, { getState }) => {
    // Retrieve CSRF token from cookies
    const { csrftoken } = cookie.parse(document.cookie);
    if (csrftoken) {
      headers.set('X-CSRFTOKEN', csrftoken);
    }

    // Retrieve tokens from Redux state
    const { accessToken } = getState().auth;

    if (accessToken) {
      // If OAuth2 access token exists, use it with Bearer scheme
      headers.set('Authorization', `JWT ${accessToken}`);
    }

    const { accountOverride } = getState().auth;
    if (accountOverride) {
      headers.set('X-Account-Override', accountOverride);
    }

    return headers;
  },
});

// Handle Django Decimal fields
const customBaseQuery = async (args, api, extraOptions) => {
  const rawResponse = await myBaseQuery(args, api, extraOptions);
  const transformedData = handleDjangoDecimalFields(rawResponse.data);
  return { ...rawResponse, body: transformedData };
};

const api = createApi({
  reducerPath: 'api',
  baseQuery: customBaseQuery, // Use customBaseQuery instead of myBaseQuery

  endpoints: (builder) => ({
    // Auth
    getCurrentUser: builder.query({
      query: (params = {}) => ({ url: 'auth/user/me/', params }),
      keepUnusedDataFor: Infinity,
      providesTags: ['CurrentUser'],
    }),
    updateCurrentUser: builder.mutation({
      query: (updatedUserData) => ({
        url: 'auth/user/me/',
        method: 'PATCH',
        body: updatedUserData,
      }),
      invalidatesTags: ['CurrentUser'],
    }),

    // Accounts
    getAccounts: builder.query({
      query: (params = {}) => ({
        url: 'accounts/',
        params,
      }),
      keepUnusedDataFor: Infinity,
      providesTags: ['Accounts'],
    }),
    getAccountById: builder.query({
      query: (id) => ({
        url: `accounts/${id}/`,
      }),
      providesTags: (result, error, id) => [{ type: 'Accounts', id }],
    }),
    addAccount: builder.mutation({
      query: (account) => ({ url: 'accounts/', method: 'POST', body: account }),
      invalidatesTags: ['Accounts'],
    }),
    updateAccount: builder.mutation({
      query: ({ id, ...rest }) => ({ url: `accounts/${id}/`, method: 'PUT', body: rest }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Accounts', id }],
    }),
    deleteAccount: builder.mutation({
      query: (id) => ({ url: `accounts/${id}/`, method: 'DELETE' }),
      invalidatesTags: (result, error, id) => [{ type: 'Accounts', id }],
    }),

    // Users
    getUsersWithCapacity: builder.query({
      query: ({ year, month, ...params }) => ({ url: `user/${year}/${month}/`, params }),
      providesTags: ['Users'],
    }),
    getUsers: builder.query({
      query: (params = {}) => ({ url: 'auth/user/', params, keepUnusedDataFor: 0 }),
      providesTags: ['Users'],
    }),
    getUserById: builder.query({
      query: (id) => `auth/user/${id}/`,
      providesTags: (result, error, id) => [{ type: 'Users', id }],
    }),
    addUser: builder.mutation({
      query: (user) => ({ url: 'auth/user/', method: 'POST', body: user }),
      invalidatesTags: ['Users'],
    }),
    updateUser: builder.mutation({
      query: ({ id, ...rest }) => ({ url: `auth/user/${id}/`, method: 'PUT', body: rest }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Users', id }],
    }),
    deleteUser: builder.mutation({
      query: (id) => ({ url: `auth/user/${id}/`, method: 'DELETE' }),
      invalidatesTags: (result, error, id) => [{ type: 'Users', id }],
    }),

    // Pipeline
    getPipelines: builder.query({
      query: (params = {}) => ({
        url: 'pipelines/',
        params,
      }),
      keepUnusedDataFor: Infinity,
      providesTags: ['pipeline'],
    }),
    getPipelineById: builder.query({
      query: (id) => ({
        url: `pipelines/${id}/`,
      }),
      providesTags: (result, error, id) => [{ type: 'pipeline', id }],
    }),
    addPipeline: builder.mutation({
      query: (account) => ({ url: 'pipelines/', method: 'POST', body: account }),
      invalidatesTags: ['pipeline'],
    }),
    updatePipeline: builder.mutation({
      query: ({ id, ...rest }) => ({ url: `pipelines/${id}/`, method: 'PUT', body: rest }),
      invalidatesTags: (result, error, { id }) => [{ type: 'pipeline', id }],
    }),
    deletePipeline: builder.mutation({
      query: (id) => ({ url: `pipelines/${id}/`, method: 'DELETE' }),
      invalidatesTags: (result, error, id) => [{ type: 'pipeline', id }],
    }),

    // GA4 BigQuery Export
    getSourceGA4BigQueries: builder.query({
      query: (params = {}) => ({ url: 'source/ga4_bigquery/', params }),
      providesTags: ['SourceGA4BigQuery'],
    }),
    getSourceGA4BigQueryById: builder.query({
      query: (id) => ({ url: `source/ga4_bigquery/${id}/` }),
      providesTags: (result, error, id) => [{ type: 'SourceGA4BigQuery', id }],
    }),
    addSourceGA4BigQuery: builder.mutation({
      query: (sourceGA4BigQuery) => ({
        url: 'source/ga4_bigquery/',
        method: 'POST',
        body: sourceGA4BigQuery,
      }),
      invalidatesTags: ['SourceGA4BigQuery'],
    }),
    updateSourceGA4BigQuery: builder.mutation({
      query: ({ id, ...rest }) => ({
        url: `source/ga4_bigquery/${id}/`,
        method: 'PUT',
        body: rest,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'SourceGA4BigQuery', id }],
    }),
    deleteSourceGA4BigQuery: builder.mutation({
      query: (id) => ({ url: `source/ga4_bigquery/${id}/`, method: 'DELETE' }),
      invalidatesTags: (result, error, id) => [{ type: 'SourceGA4BigQuery', id }],
    }),

    // Queued Jobs
    getQueuedJobs: builder.query({
      query: (params = {}) => ({ url: 'queued_jobs/', params }),
      providesTags: ['QueuedJobs'],
    }),
    getQueuedJobById: builder.query({
      query: (id) => ({ url: `queued_jobs/${id}/` }),
      providesTags: (result, error, id) => [{ type: 'QueuedJobs', id }],
    }),
    addQueuedJob: builder.mutation({
      query: (queuedJob) => ({ url: 'queued_jobs/', method: 'POST', body: queuedJob }),
      invalidatesTags: ['QueuedJobs'],
    }),
    updateQueuedJob: builder.mutation({
      query: ({ id, ...rest }) => ({ url: `queued_jobs/${id}/`, method: 'PUT', body: rest }),
      invalidatesTags: (result, error, { id }) => [{ type: 'QueuedJobs', id }],
    }),
    deleteQueuedJob: builder.mutation({
      query: (id) => ({ url: `queued_jobs/${id}/`, method: 'DELETE' }),
      invalidatesTags: (result, error, id) => [{ type: 'QueuedJobs', id }],
    }),
  }),
});

export const {
  useGetCurrentUserQuery,
  useUpdateCurrentUserMutation,
  useGetAccountsQuery,
  useGetAccountByIdQuery,
  useAddAccountMutation,
  useUpdateAccountMutation,
  useDeleteAccountMutation,

  // Users
  useGetUsersWithCapacityQuery,
  useGetUsersQuery,
  useGetUserByIdQuery,
  useAddUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,

  // Pipeline
  useGetPipelinesQuery,
  useGetPipelineByIdQuery,
  useAddPipelineMutation,
  useUpdatePipelineMutation,
  useDeletePipelineMutation,

  // GA4 BigQuery Export
  useGetSourceGA4BigQueriesQuery,
  useGetSourceGA4BigQueryByIdQuery,
  useAddSourceGA4BigQueryMutation,
  useUpdateSourceGA4BigQueryMutation,
  useDeleteSourceGA4BigQueryMutation,

  // Queued Jobs
  useGetQueuedJobsQuery,
  useGetQueuedJobByIdQuery,
  useAddQueuedJobMutation,
  useUpdateQueuedJobMutation,
  useDeleteQueuedJobMutation,
} = api;

export default api;
