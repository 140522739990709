import React from 'react';

import CommonActionSingle from 'components/CommonActionSingle/CommonActionSingle';
import FlexListTable from 'components/FlexListTable/FlexListTable'; // Adjust the import path
import { SOURCE_GA4_BIGQUERY_UPDATE_FORM } from 'routes/constants';
import { sourceGA4BigQuerySchemaList } from 'schemas'; // Adjust the import path
import { useGetSourceGA4BigQueriesQuery } from 'services/rtkApi'; // Adjust the import path

const SourceGA4BigQueryTable = (pipelineId) => {
  const { data, error, isLoading } = useGetSourceGA4BigQueriesQuery(
    pipelineId ? { pipeline: pipelineId } : {}
  );

  // Optional: Validate or parse data with Zod (if needed)
  const validatedData =
    data && data.results.length > 0 ? sourceGA4BigQuerySchemaList.parse(data.results[0]) : [];

  const columns = React.useMemo(
    () => [
      { Header: 'ID', accessor: 'id' },
      { Header: 'Name', accessor: 'name' },
      { Header: 'Project', accessor: 'bq_project_id' },
      { Header: 'Dataset', accessor: 'bq_export_dataset_id' },
      // Add more columns as needed
    ],
    []
  );

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error loading GA4 pipelines</div>;

  // When we have no data we want to:
  let outputTable;

  if (data.results.length > 0) {
    outputTable = (
      <FlexListTable
        columns={columns}
        data={data.results || []}
        hiddenColumnIds={['id']}
        linkPattern={SOURCE_GA4_BIGQUERY_UPDATE_FORM}
      />
    );
  } else {
    outputTable = (
      <CommonActionSingle
        title="You've got no data sources. Let's add one!"
        buttonName="Add data"
        href={SOURCE_GA4_BIGQUERY_UPDATE_FORM}
        description={
          <div>
            <p>Add a GA4 reporting set to get started.</p>
            <p>This will typically be all the GA4 sources associated with one website. </p>
          </div>
        }
      />
    );
  }

  return outputTable;
};

export default SourceGA4BigQueryTable;
