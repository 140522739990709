import React from 'react';
import { Link } from 'react-router-dom';

import { TERMS_AND_CONDITIONS, PRIVACY_POLICY } from 'routes/constants';

const LoggedOutLegalLinks = () => (
  <div className="border-t border-gray-300 mt-4 pt-4">
    <Link
      to={TERMS_AND_CONDITIONS}
      className="text-sm font-semibold leading-6 text-gray-600 hover:text-gray-900 mr-4"
    >
      Terms and Conditions
    </Link>
    <Link
      to={PRIVACY_POLICY}
      className="text-sm font-semibold leading-6 text-gray-600 hover:text-gray-900"
    >
      Privacy Policy
    </Link>
  </div>
);

export default LoggedOutLegalLinks;
