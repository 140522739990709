// In some page component
import React from 'react';
import { useParams } from 'react-router-dom';

import SourceGA4BigQueryForm from 'components/_forms/source_ga4_bigquery_exports/SourceGA4BigQueryForm';
import PageLayout from 'templates/PageLayout';
import PageLayoutInner from 'templates/PageLayoutInner';
import PageLayoutInnerStrip from 'templates/PageLayoutInnerStrip';

const SourceGA4BigQueryFormPage = () => {
  const { id } = useParams(); // Get entityId from URL params
  const isUpdate = Boolean(id); // Determine if it's update mode
  const pageTitle = isUpdate ? 'Update GA4 Pipeline' : 'Create GA4 Pipeline'; // Set page title based on mode

  const handleSuccess = () => {
    // Handle what happens after successful form submission
    // Redirect or show a success message
  };

  return (
    <PageLayout isItGrey>
      <div>
        <PageLayoutInnerStrip>
          <h1 className="text-xl font-semibold leading-7 text-gray-900">{pageTitle}</h1>
        </PageLayoutInnerStrip>
        <PageLayoutInner>
          <SourceGA4BigQueryForm entityId={id} onSuccess={handleSuccess} />
        </PageLayoutInner>
      </div>
    </PageLayout>
  );
};

export default SourceGA4BigQueryFormPage;
