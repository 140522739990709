import React from 'react';
import { Provider } from 'react-redux';
import { HistoryRouter as Router } from 'redux-first-history/rr6';
import { PersistGate } from 'redux-persist/integration/react';

import ErrorToaster from 'components/ErrorToaster/ErrorToaster';
import AppRoutes from 'routes'; // import the AppRoutes component
import { rehydrateAuthToken } from 'services/auth/authApiInitialize';
import BaseLayout from 'templates/BaseLayout';
import SentryBoundary from 'utils/SentryBoundary';

import store, { history, persistor } from './store';

rehydrateAuthToken(); // Initialize auth token after rehydration

const App = () => (
  <SentryBoundary>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router history={history}>
          <BaseLayout>
            <ErrorToaster />
            <AppRoutes />
          </BaseLayout>
        </Router>
      </PersistGate>
    </Provider>
  </SentryBoundary>
);

export default App;
