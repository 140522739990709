import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import { classNames } from 'utils';

export default function SidebarLink({ href, current, name, indentedLevel }) {
  const paddingClass = indentedLevel === 1 ? 'py-2 pr-2 pl-7' : 'py-2 pr-2 pl-3';
  const boldClass = indentedLevel === 1 ? '' : 'font-semibold';
  return (
    <Link
      to={href}
      className={classNames(
        current ? 'bg-teal-800 text-white' : 'text-white hover:text-sky-200 hover:bg-teal-800',
        'group flex gap-x-3 rounded-md',
        paddingClass,
        `text-sm leading-6 ${boldClass}`
      )}
    >
      {name}
    </Link>
  );
}

SidebarLink.propTypes = {
  href: PropTypes.string.isRequired,
  current: PropTypes.bool,
  name: PropTypes.string.isRequired,
  indentedLevel: PropTypes.number,
};
