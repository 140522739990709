import { z } from 'zod';

export const sourceGA4BigQuerySchemaForm = z.object({
  id: z.number().optional(),
  name: z.string(),
  property_ids: z.array(z.string()).optional(),
  timezone: z.string(),
  ga4_export_type: z.enum(['daily', 'streaming']).optional(),
  using_ecommerce: z.boolean().optional(),
  bq_project_id: z.string(),
  bq_export_dataset_id: z.string(),
});

export const sourceGA4BigQuerySchemaList = z.object({
  id: z.number().optional(),
  name: z.string(),
  bq_project_id: z.string(),
  bq_export_dataset_id: z.string(),
});
