import { push } from 'redux-first-history';
import { call, put, race, take, takeLatest, takeEvery, delay, select } from 'redux-saga/effects';

import * as route from 'routes/constants';
import {
  login,
  getSocialRedirectUrl,
  getGoogleAPIAuthGetRedirectUrl,
  register,
  logout_backend,
  useRefreshToken,
  googleAPIAuthLogin,
} from 'services/auth/auth';
import {
  loginRequest,
  logout,
  registerRequest,
  registerSuccess,
  registerFailure,
  setTokens,
  getSocialRedirectUrlRequest,
  socialLoginSuccess,
  socialLoginFailure,
  socialLoginRequest,
  setAccessToken,
  setGoogleAPIAuthStatus,
  googleAPIAuthRequest,
  getGoogleAPIAuthRedirectUrlRequest,
  triggerLogout,
} from 'services/auth/authSlice'; // Update the path as necessary
import { setFormError, addGlobalError } from 'services/errors/errorSlice';
import api from 'services/rtkApi';
import { setUserInfo } from 'services/user/userSlice';
import { extractErrorFromAxios } from 'utils';

function* handleRegister(action) {
  try {
    // Extract the registration details
    const { payload } = action;
    const { email, password, confirmPassword: re_password } = payload;

    // Then fire our register request
    const { data, cancelled } = yield race({
      data: call(register, { email, password, re_password }),
      cancelled: take(logout().type),
    });

    // If the register call was not cancelled by a logout action
    if (!cancelled) {
      yield put(registerSuccess(data)); // Stores the token or user data

      // Push them to activate page.
      yield put(push(route.LOGIN_ACTIVATE));

      // Optionally, you can automatically log the user in after registration
      // yield put(loginRequest({ email, password }));
    } else {
      // If registration was cancelled, you might want to dispatch an action or do something else
    }
  } catch (error) {
    yield put(registerFailure(error.message));
    yield put(setFormError({ formName: 'register', errors: error.message }));
  }
}

function* handleLogin(action) {
  try {
    const { payload } = action;
    const { email, password } = payload;

    // Then fire our login request.
    const { data, cancelled } = yield race({
      data: call(login, email, password),
      cancelled: take(logout().type),
    });
    // If the login call was not cancelled by a logout action
    if (data.access && data.refresh) {
      yield put(setTokens(data));

      // Fetch user data using RTK Query's endpoint
      const userAction = api.endpoints.getCurrentUser.initiate();
      yield put(userAction);

      // Attempt to get the data. If it fails then we can trigger an error.
      const userData = yield take(api.endpoints.getCurrentUser.select());

      if (!userData.data) {
        // Handle error if user data fetching fails
        yield put(addGlobalError('Failed to fetch user data, user profile may be incomplete'));
      }

      // Go and get the account level data
      const accountAction = api.endpoints.getAccounts.initiate();
      yield put(accountAction);

      // If they have no plan push them to the plan page.

      // Otherwise push them to the homepage
      yield put(push(route.HOME));
    } else {
      yield put(setFormError({ formName: 'login', errors: 'Login cancelled by logout.' }));
    }
  } catch (error) {
    console.log(error);
    yield put(setFormError({ formName: 'login', errors: extractErrorFromAxios(error) }));
  }
}

export function* handleGetSocialRedirectUrl(action) {
  try {
    const { provider, redirect_uri } = action.payload;
    const data = yield call(getSocialRedirectUrl, provider, redirect_uri);

    window.location.href = data.authorization_url;
  } catch (error) {
    yield put(setFormError({ formName: 'login', errors: error.message }));
  }
}

export function* handleSocialLogin(action) {
  try {
    const { provider, code, state } = action.payload;

    const { data, cancelled } = yield race({
      data: call(googleAPIAuthLogin, provider, code, state),
      cancelled: take(logout().type),
    });

    if (data.access && data.refresh) {
      yield put(setTokens(data));
      yield put(socialLoginSuccess());

      // Fetch user data using RTK Query's endpoint
      const userAction = api.endpoints.getCurrentUser.initiate();
      yield put(userAction);

      // Wait for the getCurrentUser result
      const { data: userData, error: userError } = yield race({
        data: take(api.endpoints.getCurrentUser.matchFulfilled),
        error: take(api.endpoints.getCurrentUser.matchRejected),
      });

      if (userError) {
        yield put(addGlobalError('Failed to fetch user data, user profile may be incomplete'));
      } else {
        yield put(setUserInfo(userData.payload));
      }

      const accountAction = api.endpoints.getAccounts.initiate();
      yield put(accountAction);
    }
  } catch (error) {
    yield put(socialLoginFailure(error.message));
    yield put(setFormError({ formName: 'login', errors: error.message }));
  }
}

/**
 * Takes an action with the a redirect URL and passes it to the backend to generate a redirect URL
 *
 * @param {object} action
 * @param {string} action.payload.provider
 * @param {string} action.payload.redirect_uri
 */
export function* handleGetGoogleAPIRedirectUrl(action) {
  try {
    const { provider, redirect_uri } = action.payload;
    const data = yield call(getGoogleAPIAuthGetRedirectUrl, provider, redirect_uri);

    window.location.href = data.authorization_url;
  } catch (error) {
    yield put(setFormError({ formName: 'login', errors: error.message }));
  }
}

/**
 * Listens for an action containing the Google Auth to turn into refresh tokens
 * with the backend.
 *
 * @param {object} action
 * @param {string} action.payload.provider
 * @param {string} action.payload.code
 * @param {string} action.payload.state
 */
export function* handleGoogleAPIAuthRegister(action) {
  try {
    const { provider, code, state } = action.payload;

    const response = yield call(googleAPIAuthLogin, provider, code, state);

    // Check if status code is positive
    if (response.status > 0) {
      yield put(setGoogleAPIAuthStatus(true));
    }

    // Redirect to the permissions page
    yield put(push(route.GOOGLE_API_PERMISSIONS_CALLBACK));
  } catch (error) {
    const userFriendlyError = error.response?.data?.error || error.message;
    yield put(addGlobalError(userFriendlyError || 'An error occurred during the request.'));
    // Redirect to the permissions page
    yield put(push(route.GOOGLE_API_PERMISSIONS_REGISTER));
  }
}

function* refreshTokenSaga() {
  while (true) {
    yield delay(5 * 60 * 500); // 4 minutes
    const refreshToken = yield select((state) => state.auth.refreshToken);
    if (refreshToken) {
      try {
        const data = yield call(useRefreshToken, refreshToken);

        yield put(setAccessToken(data.access));
      } catch (error) {
        console.log('refreshTokenSaga', error);
        // Handle refresh error, possibly logout
        // yield put(logout());
      }
    }
  }
}

function* watchAuthApiErrors() {
  yield takeEvery(
    (action) => action.type.endsWith('rejected'),
    function* handleApiError(action) {
      if (action.payload?.status === 401 || action.payload?.status === 403) {
        // Dispatch logout action
        yield put(logout());
      }
    }
  );
}

function* handleLogout() {
  try {
    yield call(logout_backend); // Call the API to delete the token
    yield put(logout()); // Dispatch the logout action to update the state
    yield put(push('/login')); // Redirect to login page
  } catch (error) {
    console.error('Logout failed', error);
  }
}

function* logoutSaga() {
  yield takeLatest(triggerLogout.type, handleLogout);
}

function* registerSaga() {
  yield takeLatest(registerRequest.type, handleRegister);
}

function* loginSaga() {
  yield takeLatest(loginRequest.type, handleLogin);
}

function* socialLoginSaga() {
  yield takeLatest(socialLoginRequest.type, handleSocialLogin);
}

function* getSocialRedirectUrlSaga() {
  yield takeLatest(getSocialRedirectUrlRequest.type, handleGetSocialRedirectUrl);
}

function* googleAPIAuthRegisterSaga() {
  yield takeLatest(googleAPIAuthRequest.type, handleGoogleAPIAuthRegister);
}

function* googleAPIAuthRedirectUrlSaga() {
  yield takeLatest(getGoogleAPIAuthRedirectUrlRequest.type, handleGetGoogleAPIRedirectUrl);
}

export {
  watchAuthApiErrors,
  loginSaga,
  socialLoginSaga,
  getSocialRedirectUrlSaga,
  registerSaga,
  logoutSaga,
  refreshTokenSaga,
  googleAPIAuthRegisterSaga,
  googleAPIAuthRedirectUrlSaga,
};
