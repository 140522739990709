import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from React Router

import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner'; // Adjust the import path as needed

const Button = ({
  loading,
  disabled,
  type,
  children,
  className,
  onClick,
  variant,
  href,
  statusColour,
}) => {
  const primaryClass = 'bg-teal-900 hover:bg-blue-500 text-white';
  const secondaryClass = 'text-white';
  const disabledClass = 'bg-gray-300 text-gray-400 cursor-not-allowed pointer-events-none';
  const successClass = 'bg-green-700 hover:bg-green-700 text-white';
  const failClass = 'bg-red-700 hover:bg-red-700 text-white';

  const isDisabled = loading || disabled;
  const baseClass =
    'rounded px-3 py-2 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600';
  const variantClass = isDisabled ? '' : variant === 'primary' ? primaryClass : secondaryClass;
  const statusClass = statusColour ? (statusColour === 'success' ? successClass : failClass) : '';
  const combinedClass = `${baseClass} ${statusClass || variantClass} ${
    isDisabled ? disabledClass : ''
  } ${className}`;

  // Are we doing a link or custom onclick
  const ButtonContent = (
    <button type={type} className={combinedClass} disabled={isDisabled} onClick={onClick}>
      {loading ? <LoadingSpinner /> : children}
    </button>
  );

  return href ? (
    <Link to={href} className={className}>
      {ButtonContent}
    </Link>
  ) : (
    ButtonContent
  );
};

Button.propTypes = {
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  children: PropTypes.node,
  href: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  variant: PropTypes.oneOf(['primary', 'secondary']),
  statusColour: PropTypes.oneOf(['success', 'fail', '', null]),
};

Button.defaultProps = {
  loading: false,
  disabled: false,
  type: 'button',
  onClick: () => {},
  variant: 'primary',
  statusColour: '',
};

export default Button;
