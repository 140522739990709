import { zodResolver } from '@hookform/resolvers/zod';
import PropTypes from 'prop-types';
import React, { useState, useEffect, Fragment } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import Button from 'components/Button/Button';
import Tabs from 'components/Tabs/Tabs';
import { toTitleCase, getZodInnerType, convertDatesInInitialData } from 'utils';

import ArrayOfObjectsField from '../FormArrayOfObjectsField/FormArrayOfObjectsField';
import { generateFieldComponent } from '../formUtils';

// We want this to support date

const CrudForm = ({
  schema,
  mutationHook,
  queryHook,
  entityId,
  onSuccess,
  formType,
  foreignKeyOptions,
  tabs,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
    getValues,
    watch,
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues: {},
  });

  const [triggerMutation, { isLoading: isMutating }] = mutationHook();
  const { data: entityData, isSuccess } = entityId && queryHook ? queryHook(entityId) : {};

  const [buttonStatus, setButtonStatus] = useState(null); // New state for button status

  useEffect(() => {
    // Determine the correct set of initial values
    const valuesToReset = formType === 'update' && isSuccess ? entityData : {};

    // Check if valuesToReset is not empty and is structured correctly
    if (valuesToReset) {
      const convertedValues = convertDatesInInitialData(valuesToReset, schema);

      reset(convertedValues);
    }
  }, [formType, isSuccess, entityData, reset, schema]);

  // Watching a specific field (replace 'dateFieldName' with your actual field name)
  const watchedDate = watch('end_date');

  // Internal function to initialize and resolve tabs
  const initializeTabs = (tabs, schema) => {
    let tabConfig = [];

    if (tabs && tabs.length > 0) {
      // Use the user-defined tabs
      tabs.forEach((tab) => {
        tabConfig.push({ ...tab }); // Spread the properties of each tab
      });
    } else {
      // Default to include all fields in the first tab
      tabConfig = [
        { key: 'default', tabName: 'Main', fieldList: Object.keys(schema.shape), description: '' },
      ];
    }

    // Ensure all fields are included in at least one tab
    Object.keys(schema.shape).forEach((field) => {
      if (!tabConfig.some((tab) => tab.fieldList.includes(field))) {
        tabConfig[0].fieldList.push(field); // Add to the first tab
      }
    });

    return tabConfig;
  };

  // Tab handling
  const [tabConfig, setTabConfig] = useState(initializeTabs(tabs, schema));
  const [tabErrorCounts, setTabErrorCounts] = useState({});

  // Updated tabKeys to align with tabConfig
  const tabKeys = tabConfig.map((tab) => tab.key);

  // State for current tab key and index
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const currentTab = tabConfig[currentTabIndex];

  useEffect(() => {
    const calculateErrorCounts = () => {
      const errorCounts = {};
      tabConfig.forEach((tab) => {
        errorCounts[tab.key] = tab.fieldList.reduce(
          (count, field) => count + (errors[field] ? 1 : 0),
          0
        );
      });
      return errorCounts;
    };

    const errorCounts = calculateErrorCounts();
    setTabErrorCounts(errorCounts);
  }, [errors, tabConfig]);

  const nextTab = () => {
    if (currentTabIndex < tabKeys.length - 1) {
      setCurrentTabIndex(currentTabIndex + 1);
    }
  };

  const previousTab = () => {
    if (currentTabIndex > 0) {
      setCurrentTabIndex(currentTabIndex - 1);
    }
  };

  const onSubmit = async (data) => {
    // Create a new object to avoid mutating the original `data`
    const formattedData = { ...data };

    // Format date fields
    Object.keys(formattedData).forEach((key) => {
      const dateValue = formattedData[key];
      if (dateValue instanceof Date) {
        const [isoDate] = dateValue.toISOString().split('T');
        formattedData[key] = isoDate;
      }
    });

    try {
      let result;
      // Check the form type and trigger the appropriate mutation
      if (formType === 'create') {
        result = await triggerMutation(formattedData).unwrap();
        setButtonStatus('created');
      } else if (formType === 'update') {
        result = await triggerMutation({ ...formattedData, id: formattedData.id }).unwrap();
        setButtonStatus('updated');
      }

      if (onSuccess) {
        onSuccess(result.id); // Pass the new item's ID to the onSuccess callback
      }
    } catch (error) {
      console.error('Form submission error:', error);
      setButtonStatus('failed');
    }

    setTimeout(() => setButtonStatus(null), 1000); // Reset button status after 1 second
  };

  // Function to handle tabs and decide which fields to render
  // Adjusted to find current tab based on key
  const generateFieldsForCurrentTab = (schema, currentTab) => {
    const currentTabConfig = tabConfig.find((tab) => tab.key === currentTab.key);
    const fieldsForTab = currentTabConfig.fieldList;

    return fieldsForTab.map((key) => {
      const value = schema.shape[key];
      const innerType = getZodInnerType(value);

      const isForeignKey = foreignKeyOptions[key] !== null && foreignKeyOptions[key] !== undefined;

      if (innerType instanceof z.ZodArray && innerType.element instanceof z.ZodObject) {
        console.log('Array of objects');
        return (
          <ArrayOfObjectsField
            key={key}
            name={key}
            control={control}
            schema={innerType.element}
            register={register}
            errors={errors}
            foreignKeyOptions={foreignKeyOptions}
          />
        );
        // eslint-disable-next-line no-else-return
      } else if (innerType instanceof z.ZodArray && !isForeignKey) {
        // return (
        //   <FormArrayField
        //     key={key}
        //     name={key}
        //     schema={schema}
        //     register={register}
        //     control={control}
        //     errors={errors}
        //   />
        // );
      }

      const baseField = generateFieldComponent(
        key,
        key,
        value,
        register,
        control,
        errors,
        foreignKeyOptions
      );
      return <Fragment key={key}>{baseField}</Fragment>;
    });
  };

  return (
    <>
      {/* Tabs for navigation */}
      <Tabs
        tabs={tabConfig}
        errorCounts={tabErrorCounts}
        currentTab={currentTab.key}
        onTabClick={(key) => setCurrentTabIndex(tabKeys.indexOf(key))}
      />

      <div className="space-y-10 divide-y divide-gray-900/10">
        <div className="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-5">
          <div className="px-4 sm:px-0">
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              {currentTab.tabName}
            </h2>
            <div className="mt-1 text-sm leading-6 text-gray-600">
              {currentTab.description || 'Fill in the fields below'}
            </div>
          </div>
          <form
            className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-4 "
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="px-4 py-6 sm:p-8">
              <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                {/* Fields for the current tab. We only render current tab */}
                {generateFieldsForCurrentTab(schema, currentTab)}
              </div>
            </div>
            <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
              {Object.keys(errors).length > 0 && (
                <div className="bg-red-50 text-red-400 mr-10 p-3 rounded-md">
                  Form has errors. Please check tabs and fix errors, e.g., missing data.
                </div>
              )}

              <Button
                statusColour={
                  buttonStatus === 'created' || buttonStatus === 'updated'
                    ? 'success'
                    : buttonStatus === 'failed'
                    ? 'fail'
                    : ''
                }
                loading={isMutating}
                type="submit"
                variant="primary"
              >
                {buttonStatus === 'created' && <span>✓ Created</span>}
                {buttonStatus === 'updated' && <span>✓ Updated</span>}
                {buttonStatus === 'failed' && <span>✗ Failed</span>}
                {!buttonStatus && (formType === 'create' ? 'Create' : 'Save')}
              </Button>
              {tabs && (
                <>
                  <Button
                    disabled={currentTabIndex === 0}
                    onClick={previousTab}
                    variant="secondary"
                  >
                    Previous
                  </Button>
                  <Button
                    disabled={currentTabIndex === tabKeys.length - 1}
                    onClick={nextTab}
                    variant="secondary"
                  >
                    Next
                  </Button>
                </>
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

CrudForm.defaultProps = {
  entityId: undefined,
  foreignKeyOptions: {},
  queryHook: undefined,
  onSuccess: undefined,
  tabs: undefined,
};

CrudForm.propTypes = {
  schema: PropTypes.object.isRequired,
  mutationHook: PropTypes.func.isRequired,
  queryHook: PropTypes.func,
  entityId: PropTypes.any,
  onSuccess: PropTypes.func,
  formType: PropTypes.oneOf(['create', 'update']).isRequired,
  foreignKeyOptions: PropTypes.object,
  tabs: PropTypes.arrayOf(PropTypes.shape()),
};

export default CrudForm;
