// In some page component
import React from 'react';
import { useParams } from 'react-router-dom';

import MarkdownRenderer from 'components/MarkdownRenderer/MarkdownRenderer';
import markdownContent from 'content/privacy.md';
import PageLayout from 'templates/PageLayout';
import PageLayoutInner from 'templates/PageLayoutInner';
import PageLayoutInnerStrip from 'templates/PageLayoutInnerStrip';

const PrivacyPolicy = () => {
  return (
    <PageLayout isItGrey>
      <div>
        <PageLayoutInnerStrip>
          <h1 className="text-xl font-semibold leading-7 text-gray-900">Privacy Policy</h1>
        </PageLayoutInnerStrip>
        <PageLayoutInner>
          <div className="prose max-w-6xl">
            <article>
              <h2>PRIVACY POLICY</h2>
              <i>Last updated: Aug 6, 2024 14:00</i>
              <p>
                We value your privacy very highly. Please read this Privacy Policy carefully before
                using the Piped Out Pipeline Application (the &quot;Application&quot;) operated by
                PipedOut LTD, a(n) Private Limited Company formed in England, with a registration
                number of 12820465 (&quot;us,&quot; &quot;we,&quot; &quot;our&quot;) as this Privacy
                Policy contains important information regarding your privacy and how we may use the
                information we collect about you.
              </p>

              <p>
                Your access to and use of the Application is conditional upon your acceptance of and
                compliance with this Privacy Policy. This Privacy Policy applies to everyone,
                including, but not limited to: visitors, users, and others, who wish to access or
                use the Application.
              </p>

              <p>
                By accessing or using the Application, you agree to be bound by this Privacy Policy.
                If you disagree with any part of the Privacy Policy, then you do not have our
                permission to access or use the Application.
              </p>

              <h2>
                What information we collect, where we get this information, how we use this
                information, what happens if we don't have it, and the legal basis for processing
                this information
              </h2>
              <p>
                We collect and store any and all information that you enter on this Application. We
                collect and store the following information about you:
              </p>
              <div class="table-responsive">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th>Category of information</th>
                      <th>Specific piece of information</th>
                      <th>Legal basis for processing this information</th>
                      <th>Where we get this information</th>
                      <th>How we use this information</th>
                      <th>What happens if we don't have this information</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr data-heading="Identifying information - Name">
                      <td>Identifying information</td>
                      <td>Name</td>
                      <td>
                        <ul>
                          <li>Processing is necessary to perform a contract with the user.</li>
                        </ul>
                      </td>
                      <td>
                        <ul>
                          <li>Information submitted by the consumer.</li>
                        </ul>
                      </td>
                      <td>
                        <ul>
                          <li>Analytics;</li>
                          <li>Auditing transactions that the consumer has entered into;</li>
                          <li>Creating new features;</li>
                          <li>Debugging to identify and repair errors;</li>
                          <li>Detecting security incidents;</li>
                          <li>Enforcing our Terms of Service;</li>
                          <li>Marketing and advertising;</li>
                          <li>Participation in surveys and contests;</li>
                          <li>Performing services;</li>
                          <li>Processing or fulfilling orders or transactions;</li>
                          <li>Processing payments;</li>
                          <li>
                            Protecting against malicious, deceptive, fraudulent or illegal activity,
                            and prosecuting those responsible for such activities;
                          </li>
                          <li>Providing customer service;</li>
                          <li>
                            Undertaking activities to verify or maintain the quality or safety of
                            our services or devices;
                          </li>
                          <li>
                            Undertaking internal research for technological development and
                            demonstration;
                          </li>
                          <li>Verifying customer information.</li>
                        </ul>
                      </td>
                      <td>
                        <ul>
                          <li>Our use of your data for analytics will not be possible;</li>
                          <li>
                            Our use of your data for auditing transactions that the consumer has
                            entered into will not be possible;
                          </li>
                          <li>
                            Our use of your data for creating new features will not be possible;
                          </li>
                          <li>
                            Our use of your data for debugging to identify and repair errors will
                            not be possible;
                          </li>
                          <li>
                            Our use of your data for detecting security incidents will not be
                            possible;
                          </li>
                          <li>
                            Our use of your data for enforcing our Terms of Service will not be
                            possible;
                          </li>
                          <li>
                            Our use of your data for marketing and advertising will not be possible;
                          </li>
                          <li>
                            Our use of your data for participation in surveys and contests will not
                            be possible;
                          </li>
                          <li>
                            Our use of your data for performing services will not be possible;
                          </li>
                          <li>
                            Our use of your data for processing or fulfilling orders or transactions
                            will not be possible;
                          </li>
                          <li>
                            Our use of your data for processing payments will not be possible;
                          </li>
                          <li>
                            Our use of your data for protecting against malicious, deceptive,
                            fraudulent or illegal activity, and prosecuting those responsible for
                            such activities will not be possible;
                          </li>
                          <li>
                            Our use of your data for providing customer service will not be
                            possible;
                          </li>
                          <li>
                            Our use of your data for undertaking activities to verify or maintain
                            the quality or safety of our services or devices will not be possible;
                          </li>
                          <li>
                            Our use of your data for undertaking internal research for technological
                            development and demonstration will not be possible;
                          </li>
                          <li>
                            Our use of your data for verifying customer information will not be
                            possible.
                          </li>
                        </ul>
                      </td>
                    </tr>
                    <tr data-heading="Identifying information - Billing address">
                      <td>Identifying information</td>
                      <td>Billing address</td>
                      <td>
                        <ul>
                          <li>Processing is necessary to perform a contract with the user.</li>
                        </ul>
                      </td>
                      <td>
                        <ul>
                          <li>Information submitted by the consumer.</li>
                        </ul>
                      </td>
                      <td>
                        <ul>
                          <li>Enforcing our Terms of Service;</li>
                          <li>Providing customer service;</li>
                          <li>Verifying customer information.</li>
                        </ul>
                      </td>
                      <td>
                        <ul>
                          <li>
                            Our use of your data for enforcing our Terms of Service will not be
                            possible;
                          </li>
                          <li>
                            Our use of your data for providing customer service will not be
                            possible;
                          </li>
                          <li>
                            Our use of your data for verifying customer information will not be
                            possible.
                          </li>
                        </ul>
                      </td>
                    </tr>
                    <tr data-heading="Identifying information - Email address">
                      <td>Identifying information</td>
                      <td>Email address</td>
                      <td>
                        <ul>
                          <li>Processing is necessary to perform a contract with the user.</li>
                        </ul>
                      </td>
                      <td>
                        <ul>
                          <li>Information submitted by the consumer.</li>
                        </ul>
                      </td>
                      <td>
                        <ul>
                          <li>Analytics;</li>
                          <li>Auditing transactions that the consumer has entered into;</li>
                          <li>Creating new features;</li>
                          <li>Debugging to identify and repair errors;</li>
                          <li>Detecting security incidents;</li>
                          <li>Enforcing our Terms of Service;</li>
                          <li>Marketing and advertising;</li>
                          <li>Participation in surveys and contests;</li>
                          <li>Performing services;</li>
                          <li>Processing or fulfilling orders or transactions;</li>
                          <li>Processing payments;</li>
                          <li>
                            Protecting against malicious, deceptive, fraudulent or illegal activity,
                            and prosecuting those responsible for such activities;
                          </li>
                          <li>Providing customer service;</li>
                          <li>
                            Undertaking activities to verify or maintain the quality or safety of
                            our services or devices;
                          </li>
                          <li>
                            Undertaking internal research for technological development and
                            demonstration;
                          </li>
                          <li>Verifying customer information.</li>
                        </ul>
                      </td>
                      <td>
                        <ul>
                          <li>Our use of your data for analytics will not be possible;</li>
                          <li>
                            Our use of your data for auditing transactions that the consumer has
                            entered into will not be possible;
                          </li>
                          <li>
                            Our use of your data for creating new features will not be possible;
                          </li>
                          <li>
                            Our use of your data for debugging to identify and repair errors will
                            not be possible;
                          </li>
                          <li>
                            Our use of your data for detecting security incidents will not be
                            possible;
                          </li>
                          <li>
                            Our use of your data for enforcing our Terms of Service will not be
                            possible;
                          </li>
                          <li>
                            Our use of your data for marketing and advertising will not be possible;
                          </li>
                          <li>
                            Our use of your data for participation in surveys and contests will not
                            be possible;
                          </li>
                          <li>
                            Our use of your data for performing services will not be possible;
                          </li>
                          <li>
                            Our use of your data for processing or fulfilling orders or transactions
                            will not be possible;
                          </li>
                          <li>
                            Our use of your data for processing payments will not be possible;
                          </li>
                          <li>
                            Our use of your data for protecting against malicious, deceptive,
                            fraudulent or illegal activity, and prosecuting those responsible for
                            such activities will not be possible;
                          </li>
                          <li>
                            Our use of your data for providing customer service will not be
                            possible;
                          </li>
                          <li>
                            Our use of your data for undertaking activities to verify or maintain
                            the quality or safety of our services or devices will not be possible;
                          </li>
                          <li>
                            Our use of your data for undertaking internal research for technological
                            development and demonstration will not be possible;
                          </li>
                          <li>
                            Our use of your data for verifying customer information will not be
                            possible.
                          </li>
                        </ul>
                      </td>
                    </tr>
                    <tr data-heading="Identifying information - Device identifier">
                      <td>Identifying information</td>
                      <td>Device identifier</td>
                      <td>
                        <ul>
                          <li>Processing is necessary to perform a contract with the user.</li>
                        </ul>
                      </td>
                      <td>
                        <ul>
                          <li>Information submitted by the consumer.</li>
                        </ul>
                      </td>
                      <td>
                        <ul>
                          <li>Analytics;</li>
                          <li>Debugging to identify and repair errors;</li>
                          <li>Detecting security incidents;</li>
                          <li>Marketing and advertising;</li>
                          <li>Performing services;</li>
                          <li>
                            Protecting against malicious, deceptive, fraudulent or illegal activity,
                            and prosecuting those responsible for such activities;
                          </li>
                          <li>
                            Undertaking activities to verify or maintain the quality or safety of
                            our services or devices;
                          </li>
                          <li>Verifying customer information.</li>
                        </ul>
                      </td>
                      <td>
                        <ul>
                          <li>Our use of your data for analytics will not be possible;</li>
                          <li>
                            Our use of your data for debugging to identify and repair errors will
                            not be possible;
                          </li>
                          <li>
                            Our use of your data for detecting security incidents will not be
                            possible;
                          </li>
                          <li>
                            Our use of your data for marketing and advertising will not be possible;
                          </li>
                          <li>
                            Our use of your data for performing services will not be possible;
                          </li>
                          <li>
                            Our use of your data for protecting against malicious, deceptive,
                            fraudulent or illegal activity, and prosecuting those responsible for
                            such activities will not be possible;
                          </li>
                          <li>
                            Our use of your data for undertaking activities to verify or maintain
                            the quality or safety of our services or devices will not be possible;
                          </li>
                          <li>
                            Our use of your data for verifying customer information will not be
                            possible.
                          </li>
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <p></p>
              <p>
                We are not required to collect this information by a law, court order, nor by a
                tribunal order. We combine or link the personal information that we hold about you.
              </p>
              <p>
                Note that we use your personal information for the purposes of profiling and
                automated decision making. Please see the "Your Rights" section below for more
                information regarding exercising your privacy rights related to profiling and
                automated decision making.
              </p>
              <h2>With whom we share your personal information</h2>
              <p>
                We share the personal information below with the following third parties for the
                following reason(s):
              </p>
              <div class="table-responsive">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th>Category of personal information shared</th>
                      <th>Specific piece of personal information shared</th>
                      <th>
                        Categories of third parties with whom personal information is/was shared
                      </th>
                      <th>Reason(s) for sharing</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr data-heading="Identifying information - Name">
                      <td>Identifying information</td>
                      <td>Name</td>
                      <td>
                        <ul>
                          <li>Email marketing vendors;</li>
                          <li>Financial transactions processors;</li>
                          <li>Hosting providers.</li>
                        </ul>
                      </td>
                      <td>
                        <ul>
                          <li>To market to you via email;</li>
                          <li>To process transactions;</li>
                          <li>To manage our interactions with you.</li>
                        </ul>
                      </td>
                    </tr>
                    <tr data-heading="Identifying information - Billing address">
                      <td>Identifying information</td>
                      <td>Billing address</td>
                      <td>
                        <ul>
                          <li>Financial transactions processors.</li>
                        </ul>
                      </td>
                      <td>
                        <ul>
                          <li>To process transactions.</li>
                        </ul>
                      </td>
                    </tr>
                    <tr data-heading="Identifying information - Email address">
                      <td>Identifying information</td>
                      <td>Email address</td>
                      <td>
                        <ul>
                          <li>Email marketing vendors;</li>
                          <li>Financial transactions processors;</li>
                          <li>Hosting providers.</li>
                        </ul>
                      </td>
                      <td>
                        <ul>
                          <li>To market to you via email;</li>
                          <li>To process transactions;</li>
                          <li>To manage our interactions with you.</li>
                        </ul>
                      </td>
                    </tr>
                    <tr data-heading="Identifying information - Device identifier">
                      <td>Identifying information</td>
                      <td>Device identifier</td>
                      <td>
                        <ul>
                          <li>Data analytics providers.</li>
                        </ul>
                      </td>
                      <td>
                        <ul>
                          <li>To analyze your interactions with us or our website.</li>
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <h2>How we protect your personal information</h2>
              <p>
                We have implemented the following measures to protect and safeguard your personal
                information:
              </p>
              <ul>
                <li>
                  Limiting the amount of personal information that we collect to strictly necessary
                  only;
                </li>
                <li>
                  Using ssl or other secure connection technologies when receiving or sending
                  personal information beyond internal networks;
                </li>
                <li>Performing regular risk assessments;</li>
                <li>Having comprehensive security policies and procedures;</li>
                <li>Screening all employees with access to personal information;</li>
                <li>Maintaining up-to-date software and safeguards.</li>
              </ul>

              <h2>Sale of your information</h2>
              <p>We do not sell your personal information.</p>

              <h2>Cookies</h2>
              <p>
                A cookie is a small piece of data sent from a application and stored on your device
                by your browser. This Application collects cookies and may use cookies for reasons
                including, but not limited to:
              </p>
              <ul>
                <li>Analyzing our Application traffic;</li>
                <li>Analyzing your interactions with advertisements;</li>
                <li>Identifying if you are signed in to the Application;</li>
                <li>Testing content on the Application;</li>
                <li>Storing information about your preferences;</li>
                <li>Recognizing when you return to the Application.</li>
              </ul>

              <p>
                Most web browsers automatically accept cookies. However, you can modify your browser
                settings to decline cookies, if you prefer. This setting may prevent you from taking
                full advantage of the Application.
              </p>

              <h2>Children&apos;s privacy</h2>

              <p>
                This Application is intended for use by a general audience and does not offer
                services to children. Should a child whom we know to be under 18 send personal
                information to us, we will use that information only to respond to that child to
                inform him or her that they cannot use this Application.
              </p>

              <h2>Analytics programs</h2>

              <p>
                This Application uses Google Analytics to collect information about you and your
                behaviors. If you would like to opt out of Google Analytics, please visit
                <a href="https://tools.google.com/dlpage/gaoptout/" target="_blank">
                  {' '}
                  https://tools.google.com/dlpage/gaoptout/
                </a>
                .
              </p>

              <h2>Information retention</h2>
              <p>
                We retain all of the information that we collect until the data is no longer needed
                for auditing, management or marketing.
              </p>

              <h2>Direct marketing</h2>
              <p>
                We use the information that we collect about you for direct marketing purposes.
                Direct marketing is the act of selling products or services directly to consumers
                rather than through retailers. Residents of Australia and/or the European Union
                and/or the European Economic Area and the United Kingdom - You may, at any time,
                request that we cease to use your information for direct marketing purposes by
                emailing us at accounts@pipedout.com.
              </p>

              <h2>Profiling</h2>
              <p>
                We use the information that we collect about you for profiling. Profiling is any
                form of automated processing of personal information consisting of the use of
                personal information to evaluate certain personal aspects relating to a person, in
                particular to analyze or predict aspects concerning that person's performance at
                work, economic situation, health, personal preferences, interests, reliability,
                behavior, location or movements.
              </p>
              <p>
                We use the following logic to conduct profiling: Typically bucketing users into how
                often they use the product, or the type of business they are.
              </p>

              <h2>Automated decision-making</h2>
              <p>
                We use the information that we collect about you for automated individual
                decision-making. Automated individual decision-making is making a decision solely by
                automated means without any human involvement.
              </p>
              <p>We use the logic below to conduct automated decision-making:</p>
              <p>
                We might use the information to decide which onboarding and marketing flows to send
                to users. E.g. a new user should probably receive the onboarding emails. The user
                who hasn&apos;t used for some time, should get a re-activation set of emails.
              </p>

              <h2>Your rights</h2>
              <p>
                Depending upon where you reside, you may have the following rights with regard to
                your personal information:
              </p>
              <div class="table-responsive">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th>Right</th>
                      <th>Applies To</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        The right to access the personal information that we have collected about
                        you.
                      </td>
                      <td>
                        Residents of Canada, Australia, the European Union and/or the European
                        Economic Area, the United Kingdom, and Quebec only
                      </td>
                    </tr>

                    <tr>
                      <td>The right to say no to the sale of your personal information.</td>
                      <td>Residents of Nevada only</td>
                    </tr>

                    <tr>
                      <td>
                        The right to request that we delete all or some of the personal information
                        that we have collected on you.
                      </td>
                      <td>
                        Residents of the European Union and/or the European Economic Area and the
                        United Kingdom only
                      </td>
                    </tr>

                    <tr>
                      <td>
                        The right to ask us to transmit your personal information that we have
                        collected on you to another provider (where technically feasible).
                      </td>
                      <td>
                        Residents of the European Union and/or the European Economic Area and the
                        United Kingdom only
                      </td>
                    </tr>

                    <tr>
                      <td>
                        The right to request that we amend any of the information that we have
                        collected about you.
                      </td>
                      <td>
                        Residents of Canada, Australia, the European Union and/or the European
                        Economic Area, the United Kingdom, and Quebec only
                      </td>
                    </tr>

                    <tr>
                      <td>The right to withdraw your consent to the processing of your data.</td>
                      <td>
                        Residents of Canada, the European Union and/or the European Economic Area,
                        the United Kingdom, and Quebec only
                      </td>
                    </tr>

                    <tr>
                      <td>The right to request that we restrict the processing of your data.</td>
                      <td>
                        Residents of the European Union and/or the European Economic Area and the
                        United Kingdom only
                      </td>
                    </tr>

                    <tr>
                      <td>
                        The right to lodge a complaint regarding our collection, sharing and
                        processing of data with competent authorities in the proper jurisdiction.
                      </td>
                      <td>
                        Residents of Canada, Australia, the European Union and/or the European
                        Economic Area, the United Kingdom, and Quebec only
                      </td>
                    </tr>

                    <tr>
                      <td>
                        The right to not have to identify yourself, or of using a pseudonym in
                        certain circumstances.
                      </td>
                      <td>Residents of Australia only</td>
                    </tr>

                    <tr>
                      <td>The right to stop receiving unwanted direct marketing.</td>
                      <td>
                        Residents of Australia and the European Union and/or the European Economic
                        Area only
                      </td>
                    </tr>

                    <tr>
                      <td>
                        The right to receive the personal information that we hold about you in a
                        portable and, to the extent feasible, a readily usable format that allows
                        you to transmit this information to another entity.
                      </td>
                      <td>
                        Residents of the European Union and/or the European Economic Area and the
                        United Kingdom only
                      </td>
                    </tr>

                    <tr>
                      <td>The right to opt out of the use of your data for profiling purposes.</td>
                      <td>Residents of Quebec only</td>
                    </tr>

                    <tr>
                      <td>
                        The right to opt out of the use of your data for automated decision making
                        purposes.
                      </td>
                      <td>Residents of Quebec only</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <h2>Exercising your rights</h2>
              <p>
                You may exercise the rights specified above by submitting a consumer request to:
              </p>
              <p>
                Dominic Woodman
                <br />
                dom at pipedout dot com
              </p>
              <p>
                We will need to verify your identity prior to effectuating your request. To verify
                your identity, you will need to provide us with the following information with your
                request:
              </p>
              <ul>
                <li>Name;</li>
                <li>Billing address;</li>
                <li>Email address.</li>
              </ul>
              <p>
                Please note that we may be unable to process your request if you do not provide us
                with the above information.
              </p>

              <p>
                We will respond to most consumer requests within 30 to 45 days of receipt, depending
                upon where you reside. However, some requests may take longer. We will notify you in
                writing if we need more time to respond. We have the ability to deny your request(s)
                if certain exceptions in the law apply. If we do deny your request, we will provide
                you with the reasons for such denial.
              </p>

              <h2>Complaints</h2>
              <p>
                You have the right to lodge a complaint regarding our collection, use and sharing of
                your personal information by contacting us at the following contact information:
              </p>
              <ul>
                <li>Dominic Woodman</li>
                <li>dom at pipedout dot com</li>
              </ul>
              <p>
                Any such complaints must first be addressed to us in writing as required by
                Australia Privacy Act 1988 S 40(1A). We will respond to all complaints within a
                reasonable time (usually 30 days). If you are not satisfied with our response to
                your complaint, you may then lodge a complaint with the Office of the Australian
                Information Commissioner by following the steps on{' '}
                <a
                  href="https://www.oaic.gov.au/privacy/privacy-complaints/lodge-a-privacy-complaint-with-us/"
                  target="_blank"
                  rel="noreferrer"
                >
                  this page
                </a>
                .
              </p>
              <h2>Accountability</h2>
              <p>
                The following person is accountable and responsible for our privacy practices and
                procedures:
              </p>
              <p>
                Dominic Woodman
                <br />
                <br />
                dom at pipedout dot com
              </p>
              <p>
                You may lodge a complaint with us by contacting the person accountable and
                responsible for our privacy practices and procedures at the contact information
                above. Residents of Quebec may lodge a complaint with the Office of the Privacy
                Commissioner of Quebec by filling out{' '}
                <a
                  href="https://www.cai.gouv.qc.ca/a-propos/nous-joindre/"
                  target="_blank"
                  rel="noreferrer"
                >
                  this form
                </a>{' '}
                or calling 1-888-528-7741. Residents of Canada may also lodge a complaint with the
                Office of the Privacy Commissioner of Canada by visiting{' '}
                <a
                  href="https://priv.gc.ca/en/report-a-concern/file-a-formal-privacy-complaint/file-a-complaint-about-a-business/"
                  target="_blank"
                  rel="noreferrer"
                >
                  this page
                </a>{' '}
                or calling 1-800-282-1376.
              </p>
              <h2>Location of data processing</h2>
              <p>All data processing activities undertaken by us take place in United Kingdom.</p>

              <h2>Data Protection Officer</h2>
              <p>
                Dominic Woodman is our Data Protection Officer and may be reached via email at dom
                at pipedout dot com.
              </p>

              <h2>Third-party websites</h2>

              <p>
                This Application may contain hyperlinks to websites operated by parties other than
                us. We provide such hyperlinks for your reference only. We do not control such
                websites and are not responsible for their contents or the privacy or other
                practices of such websites. It is up to you to read and fully understand their
                Privacy Policies. Our inclusion of hyperlinks to such websites does not imply any
                endorsement of the material on such websites or any association with their
                operators.
              </p>

              <h2>Do Not Track</h2>

              <p>
                Do Not Track is a preference you can set on your browser to inform websites that you
                do not want to be tracked. We do not support Do Not Track ("DNT"). You can either
                enable or disable Do Not Track by visiting the Preferences or Settings page of your
                browser.
              </p>

              <h2>Transferring Data</h2>
              <p>
                We plan to transfer data to The US. This means that your information may be
                processed in a country outside of Canada and it may be accessible to law enforcement
                authorities and national security authorities of that country and jurisdiction.
              </p>

              <h2>Changes to Privacy Policy</h2>

              <p>
                We reserve the right to amend this Privacy Policy at any time. We will notify you of
                any changes to this Privacy Policy by posting the updated Privacy Policy to this
                website or application.
              </p>

              <h2>Questions</h2>
              <p>
                If you have any questions about this Privacy Policy, please contact us at
                accounts@pipedout.com.
              </p>
            </article>
          </div>
        </PageLayoutInner>
      </div>
    </PageLayout>
  );
};

export default PrivacyPolicy;
