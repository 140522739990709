import React from 'react';

import FlexListTable from 'components/FlexListTable/FlexListTable'; // Adjust the import path
import { PIPELINE_ALL_SOURCE_LIST, PIPELINE_UPDATE_FORM } from 'routes/constants';
import { pipelineSchemaList } from 'schemas'; // Adjust the import path
import { useGetPipelinesQuery } from 'services/rtkApi'; // Adjust the import path

const PipelineTable = () => {
  const { data, error, isLoading } = useGetPipelinesQuery();

  // Optional: Validate or parse data with Zod (if needed)
  const validatedData =
    data && data.results.length > 0 ? pipelineSchemaList.parse(data.results[0]) : [];

  const columns = React.useMemo(
    () => [
      { Header: 'ID', accessor: 'id' },
      { Header: 'Name', accessor: 'name' },
      { Header: 'Project', accessor: 'bq_project_id' },
      { Header: 'Dataset', accessor: 'bq_export_dataset_id' },
      // Add more columns as needed
    ],
    []
  );

  const linkButtons = [
    {
      label: 'Edit Data Sources (GA4)',
      link: PIPELINE_ALL_SOURCE_LIST,
    },
    {
      label: 'Edit Pipeline',
      link: PIPELINE_UPDATE_FORM,
    },
  ];

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error loading pipelines</div>;

  return (
    <FlexListTable
      columns={columns}
      data={data.results || []}
      hiddenColumnIds={['id']}
      linkButtons={linkButtons}
    />
  );
};

export default PipelineTable;
