import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';

import { removeGlobalError } from 'services/errors/errorSlice';
import 'react-toastify/dist/ReactToastify.css';

const ErrorToaster = () => {
  const errors = useSelector((state) => state.error.errors);
  const dispatch = useDispatch();

  useEffect(() => {
    if (errors.length > 0) {
      // Display the first error as a toast
      toast.error(errors[0], {
        onClose: () => dispatch(removeGlobalError()),
      });
    }
  }, [errors, dispatch]);

  return (
    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  );
};

export default ErrorToaster;
