import PropTypes from 'prop-types';
import React from 'react';

import { toTitleCase } from 'utils';

const FormInput = ({
  fieldKey,
  label,
  register,
  errors,
  type,
  showLabel = true,
  placeholder = '',
}) => {
  const handleInputChange = (event) => {
    const value = event.target.value;
    const parsedValue = type === 'number' ? parseFloat(value) : value;
    event.target.value = parsedValue;
    return event;
  };

  return (
    <div className="sm:col-span-4">
      {showLabel && (
        <label htmlFor={fieldKey} className="block text-sm font-medium leading-6 text-gray-900">
          {toTitleCase(label)}
        </label>
      )}
      <div className="mt-2 control">
        <input
          type={
            type === 'number'
              ? 'number'
              : type === 'password'
              ? 'password'
              : fieldKey === 'email'
              ? 'email'
              : 'text'
          }
          name={fieldKey}
          id={fieldKey}
          className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          placeholder={placeholder || (fieldKey === 'email' ? 'you@example.com' : '')}
          {...register(fieldKey, {
            onChange: handleInputChange,
            valueAsNumber: type === 'number',
          })}
        />
      </div>
      {errors[fieldKey] && (
        <div className="rounded-md bg-red-50 p-2 mt-2">
          <p className=" text-sm text-red-700" id={`${fieldKey}-description`}>
            {errors[fieldKey].message}
          </p>
        </div>
      )}
    </div>
  );
};

export default FormInput;

FormInput.defaultProps = {
  type: 'text',
};

FormInput.propTypes = {
  fieldKey: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  toTitleCase: PropTypes.func.isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string,
};
