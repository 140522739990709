import { setAuthToken } from 'services/auth/authApi';
import store from 'store'; // Adjust the import based on your store setup

const initializeAuthToken = () => {
  const state = store.getState();
  const { accessToken } = state.auth; // Adjust based on your state structure

  console.log(accessToken);
  if (accessToken) {
    setAuthToken(accessToken, 'JWT');
  }
};

export const rehydrateAuthToken = () => {
  const unsubscribe = store.subscribe(() => {
    const state = store.getState();
    if (state._persist.rehydrated) {
      console.log('Tet');
      initializeAuthToken();
      unsubscribe();
    }
  });
};

export default initializeAuthToken;
