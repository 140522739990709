// authApi.js
import axios from 'axios';
import cookie from 'cookie';

const authApi = axios.create();

// Request interceptor for CSRF token
authApi.interceptors.request.use((config) => {
  const { csrftoken } = cookie.parse(document.cookie);
  if (csrftoken) {
    config.headers['X-CSRFTOKEN'] = csrftoken;
  }
  return config;
});

export const setAuthToken = (token, tokenType = 'JWT') => {
  if (token) {
    authApi.defaults.headers.common.Authorization = `${tokenType} ${token}`;
  } else {
    delete authApi.defaults.headers.common.Authorization;
  }
};

export default authApi;
