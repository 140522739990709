import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import { Controller } from 'react-hook-form';
import Select from 'react-select';

import { toTitleCase } from 'utils';

const FormSelect = (
  { fieldKey, label, control, errors, options, showLabel = true, isMulti = false },
  ref
) => {
  return (
    <div className="sm:col-span-4">
      {showLabel && (
        <label htmlFor={fieldKey} className="block text-sm font-medium leading-6 text-gray-900">
          {toTitleCase(label)}
        </label>
      )}
      <div className="mt-2 control">
        <Controller
          control={control}
          name={fieldKey}
          render={({ field }) => (
            <Select
              ref={ref} // Pass ref here
              {...field}
              options={options || []}
              isMulti={isMulti}
              value={
                isMulti
                  ? (options || []).filter((opt) => field.value?.includes(opt.value))
                  : (options || []).find((opt) => opt.value === field.value) || null
              }
              onChange={(option) =>
                field.onChange(
                  isMulti ? option.map((opt) => opt.value) : option ? option.value : ''
                )
              }
              className="w-full"
            />
          )}
        />
      </div>
      {errors[fieldKey] && (
        <p className="mt-2 text-sm text-gray-500" id={`${fieldKey}-description`}>
          {errors[fieldKey].message}
        </p>
      )}
    </div>
  );
};

export default forwardRef(FormSelect);

// FormSelect.propTypes = {
//   fieldName: PropTypes.string.isRequired,
//   control: PropTypes.object.isRequired,
//   errors: PropTypes.object.isRequired,
//   options: PropTypes.array.isRequired,
//   label: PropTypes.string,
// };
