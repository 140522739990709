import React, { Fragment } from 'react';
import { useFieldArray } from 'react-hook-form';

import { generateFieldComponent } from '../formUtils';

const ArrayOfObjectsField = ({ name, control, schema, register, errors, foreignKeyOptions }) => {
  const { fields, append, remove } = useFieldArray({ control, name });

  return (
    <div className="sm:col-span-4">
      {fields.map((item, index) => (
        <div key={item.id} className="flex justify-between items-end space-x-4">
          {Object.keys(schema.shape).map((key) => {
            const fieldKey = `${name}[${index}].${key}`;
            const fieldName = key;
            const value = schema.shape[key];

            return (
              <Fragment key={fieldKey}>
                {generateFieldComponent(
                  fieldKey,
                  fieldName,
                  value,
                  register,
                  control,
                  errors,
                  foreignKeyOptions
                )}
              </Fragment>
            );
          })}
          <button type="button" onClick={() => remove(index)}>
            Remove
          </button>
        </div>
      ))}
      <button type="button" onClick={() => append({})}>
        Add
      </button>
    </div>
  );
};

// const ArrayOfObjectsField = ({ name, control, schema, register, errors, foreignKeyOptions }) => {
//   console.log('DOMDOMDOM');
//   const { fields, append, remove } = useFieldArray({ control, name });
//   console.log(schema);
//   return (
//     <>
//       {fields.map((item, index) => (
//         <div key={item.id} className="sm:col-span-4 flex flex-wrap -mx-2">
//           {Object.keys(schema.shape).map((key) => {
//             const fieldKey = `${name}[${index}].${key}`;
//             const fieldName = key;
//             const value = schema.shape[key];
//             return (
//               <div key={fieldKey} className="px-2 w-full md:w-1/2 lg:w-1/3">
//                 {generateFieldComponent(
//                   fieldKey,
//                   value,
//                   register,
//                   control,
//                   errors,
//                   foreignKeyOptions
//                 )}
//               </div>
//             );
//           })}
//           <button type="button" onClick={() => remove(index)}>
//             Remove
//           </button>
//         </div>
//       ))}
//       <div className="sm:col-span-4">
//         <button type="button" onClick={() => append({})}>
//           Add
//         </button>
//       </div>
//     </>
//   );
// };

export default ArrayOfObjectsField;
