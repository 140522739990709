import React from 'react';
import { useParams } from 'react-router-dom';

import CrudForm from 'components/Forms/CrudForm/CrudForm';
import { pipelineSchemaForm } from 'schemas';
import {
  useUpdatePipelineMutation,
  useGetPipelineByIdQuery,
  useAddPipelineMutation,
} from 'services/rtkApi'; // adjust the import based on your API service setup

const PipelineForm = ({ onSuccess }) => {
  const { id } = useParams(); // This will get the entityId from the URL
  const isUpdate = Boolean(id);
  const formType = isUpdate ? 'update' : 'create';

  const mutationHook = isUpdate ? useUpdatePipelineMutation : useAddPipelineMutation;
  const queryHook = isUpdate ? useGetPipelineByIdQuery : undefined;

  return (
    <CrudForm
      entityId={id}
      key={`pipeline_${formType}`}
      formType={formType}
      mutationHook={mutationHook}
      queryHook={queryHook}
      schema={pipelineSchemaForm}
      tabs={[
        {
          key: 'Main',
          tabName: 'Main',
          fieldList: ['name'],
          description: (
            <div>
              <p>
                You&apos;ll typically have one pipeline per site/reporting view, so call this
                something like &apos;My Brand&apos;
              </p>
            </div>
          ),
        },
      ]}
      onSuccess={onSuccess}
    />
  );
};

export default PipelineForm;
