import React from 'react';
import { z } from 'zod';

import FormDatePicker from 'components/Forms/FormDatePicker/FormDatePicker';
import FormInput from 'components/Forms/FormInput/FormInput';
import FormSelect from 'components/Forms/FormSelect/FormSelect';
import { toTitleCase, getZodInnerType } from 'utils/index';

export const generateFieldComponent = (
  fieldKey,
  label,
  value,
  register,
  control,
  errors,
  foreignKeyOptions
) => {
  let fieldComponent;

  const innerType = getZodInnerType(value);

  if (fieldKey === 'id') {
    return null;
  }

  const isForeignKey = foreignKeyOptions[label] !== null && foreignKeyOptions[label] !== undefined;
  const isMulti = innerType instanceof z.ZodArray && innerType.element instanceof z.ZodNumber;

  if (isForeignKey) {
    fieldComponent = (
      <FormSelect
        fieldKey={fieldKey}
        label={label}
        control={control}
        errors={errors}
        options={foreignKeyOptions[fieldKey]}
        toTitleCase={toTitleCase}
        isMulti={isMulti}
      />
    );
  } else if (innerType instanceof z.ZodNumber) {
    fieldComponent = (
      <FormInput
        fieldKey={fieldKey}
        label={label}
        register={register}
        errors={errors}
        toTitleCase={toTitleCase}
        type="number"
      />
    );
  }
  // Select field
  else if (innerType instanceof z.ZodEnum) {
    const enumOptions = innerType.options.map((optionValue) => ({
      label: toTitleCase(optionValue), // Assuming you want to title case the label
      value: optionValue,
    }));

    fieldComponent = (
      <FormSelect
        fieldKey={fieldKey}
        label={label}
        control={control}
        errors={errors}
        options={enumOptions}
        toTitleCase={toTitleCase}
        isMulti={isMulti}
      />
    );

    // fieldComponent = (
    //   <div key={key} className="field">
    //     <label className="label">{toTitleCase(key)}</label>
    //     <div className="control">
    //       <div className="select">
    //         <select {...register(key)}>
    //           {value.options.map((option, index) => (
    //             <option key={index} value={option}>
    //               {option}
    //             </option>
    //           ))}
    //         </select>
    //       </div>
    //     </div>
    //     {errors[key] && <p className="help is-danger">{errors[key].message}</p>}
    //   </div>
    // );
  } else if (fieldKey.endsWith('_date')) {
    fieldComponent = (
      <FormDatePicker
        fieldKey={fieldKey}
        label={label}
        control={control}
        errors={errors}
        toTitleCase={toTitleCase}
      />
    );
  }
  // Text field as default
  else {
    fieldComponent = (
      <FormInput
        fieldKey={fieldKey}
        label={label}
        register={register}
        errors={errors}
        toTitleCase={toTitleCase}
      />
    );
  }

  return fieldComponent;
};
