import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import Button from 'components/Button/Button';
import FormSelect from 'components/Forms/FormSelect/FormSelect';
import { setAccountOverride } from 'services/auth/authSlice'; // Adjust the path
import { useGetAccountsQuery } from 'services/rtkApi';

const TenantSwitcher = () => {
  const { data: accountsData, isLoading: isLoadingAccounts } = useGetAccountsQuery();
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    dispatch(setAccountOverride(data.account));
  };

  if (isLoadingAccounts) {
    return <div>Loading...</div>;
  }

  const options =
    accountsData?.results?.map((account) => ({ value: account.id, label: account.name })) || [];

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex items-center space-x-4">
      <Controller
        name="account"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <FormSelect
            fieldKey="account"
            control={control}
            errors={errors}
            options={options}
            {...field}
          />
        )}
      />
      <Button type="submit" variant="primary">
        Submit
      </Button>
    </form>
  );
};

export default TenantSwitcher;
