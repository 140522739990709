import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import Datepicker from 'react-tailwindcss-datepicker';

const FormDatePicker = ({ fieldKey, label, control, errors, toTitleCase }) => {
  const formatDate = (date) => {
    if (!date) return { startDate: null, endDate: null };
    // If date is already in the correct format, just return it
    if (typeof date === 'object' && date.startDate) return date;

    // Convert date to YYYY-MM-DD format
    const formattedDate = date instanceof Date ? date.toISOString().split('T')[0] : date;
    return { startDate: formattedDate, endDate: formattedDate };
  };

  return (
    <div className="sm:col-span-4">
      <label htmlFor={fieldKey} className="block text-sm font-medium leading-6 text-gray-900">
        {toTitleCase(label)}
      </label>
      <div className="rounded-md shadow-sm ring-1 ring-gray-300">
        <Controller
          control={control}
          name={fieldKey}
          render={({ field: { onChange, value } }) => {
            const datePickerValue = formatDate(value);
            return (
              <Datepicker
                value={datePickerValue}
                onChange={(dateObject) => onChange(dateObject.startDate)}
                asSingle
                classNames={'ring-1'}
              />
            );
          }}
        />
      </div>
      {errors[fieldKey] && (
        <div className="rounded-md bg-red-50 p-2 mt-2">
          <p className=" text-sm text-red-700" id={`${fieldKey}-description`}>
            {errors[fieldKey].message}
          </p>
        </div>
      )}
    </div>
  );
};

FormDatePicker.propTypes = {
  fieldKey: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  toTitleCase: PropTypes.func.isRequired,
};

export default FormDatePicker;
